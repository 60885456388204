.card-rental-form {
    background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 2rem;
    transition: transform 0.2s ease;
}

.card-rental-form:hover {
    /*transform: translateY(-5px);*/
}

.rental-form-header {
    color: #2c3e50;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    position: relative;
}

.rental-form-header:after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background: #3498db;
    margin: 10px auto;
    border-radius: 2px;
}

.rental-form {
    padding: 1rem;
}

.rental-form-fields {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.rental-form-input {
    width: 100%;
    transition: transform 0.2s ease;
}

.rental-form-input:focus-within {
    transform: scale(1.02);
}

.rental-form-submit {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
}

.submit-button {
    min-width: 250px !important;
    padding: 12px 24px !important;
    font-size: 1.1rem !important;
    text-transform: none !important;
    border-radius: 30px !important;
    background: linear-gradient(45deg, #3498db, #2980b9) !important;
    box-shadow: 0 4px 15px rgba(52, 152, 219, 0.3) !important;
    transition: all 0.3s ease !important;
}

.submit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(52, 152, 219, 0.4) !important;
}

/* Override MUI styles */
.MuiTextField-root {
    background-color: #fff;
    border-radius: 8px !important;
}

.MuiInputLabel-root {
    color: #34495e !important;
    font-weight: 500 !important;
}

.MuiOutlinedInput-root {
    background-color: #fff;
    border-radius: 8px !important;
    transition: all 0.2s ease !important;
}

.MuiOutlinedInput-root:hover {
    background-color: #f8f9fa;
}

/* Payment form specific styles */
.credit-card-form {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 12px;
    margin-top: 1rem;
}

.credit-card-form .MuiTextField-root {
    margin-bottom: 1rem;
}

/* Loading spinner styles */
.spinner-container {
    text-align: center;
    padding: 3rem;
}

.spinner-text {
    color: #3498db;
    margin-top: 1rem;
    font-weight: 500;
}

/* Add these new styles */
.rental-options-section {
    padding: 1.5rem;
    margin: 1rem 0;
    background: #fff;
}

.rental-options-header {
    margin-bottom: 1rem !important;
    color: #2c3e50 !important;
    font-weight: 600 !important;
}

.rental-options-group {
    width: 100%;
}

.rental-option-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 1rem;
}

.rental-option-price {
    margin-left: 10px;
    font-weight: 600;
    color: #2980b9;
}

.rental-option-stock {
    margin-left: 1rem;
    font-size: 0.85rem;
    color: #7f8c8d;
}

.rental-total {
    margin-top: 1.5rem;
    text-align: right;
}

/* Edit Form Styles */
.rental-forms-list {
    padding: 2rem 0;
}

.section-header {
    color: #fff;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 600;
}

.forms-container {
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    padding-top: 20px;
    background: transparent !important;
    box-shadow: none !important;
}

.rental-form-card {
    transition: transform 0.2s ease, box-shadow 0.2s ease !important;
}

.rental-form-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.form-name {
    margin-bottom: 1rem !important;
    color: #2c3e50 !important;
}

.form-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.status-chip {
    margin-left: 1rem !important;
}

.no-forms-message {
    text-align: center;
    color: #7f8c8d;
    padding: 2rem;
}

.edit-form-header {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem 0;
}

.back-button {
    min-width: 160px !important;
}

.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;
}

.spinner-text {
    margin-top: 1rem;
    color: #2c3e50;
}

/* Transaction Modal Styles */
.transaction-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.transaction-paper {
    padding: 2rem;
    min-width: 400px;
    max-width: 90%;
}

.transaction-title {
    margin-bottom: 1.5rem !important;
    color: #2c3e50 !important;
}

.transaction-input {
    margin-bottom: 1.5rem !important;
}

.transaction-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}

/* Add Participant Modal Styles */
.add-participant-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    padding: 24px;
}

.participant-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    margin-top: 24px;
}

.participant-card {
    padding: 16px;
    transition: all 0.2s ease !important;
}

.participant-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.participant-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.participant-actions {
    display: flex;
    gap: 8px;
}

.participant-rentals-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    min-height: 40px;
    padding: 8px;
    background: #f5f5f5;
    border-radius: 4px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.search-controls {
    margin-bottom: 16px;
}

.waivers-list {
    overflow-y: auto;
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.edit-form-actions {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
}

/* Available Rentals Summary */
.available-rentals-summary {
    background: white;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.summary-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
}

.rental-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.rental-content {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
}

.rental-number-edit {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;
}

.rental-number-input {
    width: 80px;
    font-size: 14px;
}

.rental-number {
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 4px;
    background: #f0f0f0;
    transition: background-color 0.2s;
}

.rental-number:hover {
    background: #e0e0e0;
}

.rental-option-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.rental-option-label {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
}

.rental-quantity-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 16px;
}

.rental-option-price {
    font-weight: 500;
    color: #1976d2;
}

.return-form-root {
    padding: 2rem 0;
}

.rental-return-container {
    /* display: flex;
    flex-direction: column;
    gap: 24px; */
}

.page-title {
    color: #1976d2;
    margin-bottom: 24px !important;
}

.return-rental-forms-list {
    border-radius: 12px !important;
    overflow: hidden;
}

.rental-form-item {
    transition: background-color 0.2s;
}

.rental-form-item:hover {
    background-color: rgba(25, 118, 210, 0.04);
}

.corrupted-form-item {
    background-color: rgba(211, 47, 47, 0.04);
}

.form-avatar {
    background-color: #1976d2 !important;
    color: white !important;
}

.warning-avatar {
    background-color: #d32f2f !important;
    color: white !important;
}

.completion-container {
    padding: 32px;
    text-align: center;
    max-width: 600px;
    margin: 32px auto;
    border-radius: 12px !important;
}

.completion-title {
    color: #1976d2;
    margin-bottom: 16px !important;
}

.completion-text {
    margin-bottom: 24px !important;
    color: #666;
}

.email-input {
    margin-bottom: 24px !important;
}

.complete-button {
    margin-bottom: 24px !important;
}

.company-logo {
    max-width: 120px;
    opacity: 0.8;
}

.return-form-details {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rentals-table-container {
    border-radius: 12px !important;
    overflow: hidden;
}

.group-name {
    padding: 16px;
    background-color: #f5f5f5;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rental-numbers {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 8px 0;
}

.rental-number-chip {
    min-width: 80px !important;
    /* Minimum width */
    height: auto !important;
    /* Allow height to adjust */
    min-height: 36px !important;
    /* Minimum height */
    padding: 8px 16px !important;
    /* Increased padding */
    font-size: 16px !important;
    font-weight: 500 !important;
    white-space: normal !important;
    /* Allow text wrapping */
    border-radius: 18px !important;
    cursor: pointer !important;
    transition: all 0.2s ease !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.rental-number-chip:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
    background-color: rgba(25, 118, 210, 0.08) !important;
}

.rental-number-chip.MuiChip-colorSuccess {
    background-color: rgba(46, 125, 50, 0.12) !important;
    color: #2e7d32 !important;
    border-color: rgba(46, 125, 50, 0.3) !important;
}

.rental-number-chip.MuiChip-colorSuccess:hover {
    background-color: rgba(46, 125, 50, 0.2) !important;
    box-shadow: 0 2px 8px rgba(46, 125, 50, 0.2) !important;
}

.rental-number-chip.MuiChip-label {
    overflow: visible !important;
}

/* Add responsive styles */
@media (max-width: 600px) {
    .rental-numbers {
        gap: 8px;
    }

    .rental-number-chip {
        font-size: 14px !important;
        height: 32px !important;
        padding: 0 12px !important;
    }
}

/* Container Styles */
.rental-options-container {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 2rem 0;
}

/* Header Styles */
.rental-header {
    color: #1976d2;
    font-weight: 600;
    position: relative;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
}

.rental-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 4px;
    background-color: #1976d2;
    border-radius: 2px;
}

/* Card Styles */
.rental-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.rental-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

/* Input Field Styles */
.rental-input {
    background-color: #f8f9fa;
    border-radius: 8px;
}

.rental-input.Mui-disabled {
    background-color: #eef1f5;
}

.rental-input .MuiInputBase-input {
    padding: 12px 16px;
}

.rental-input .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.12);
}

/* Button Styles */
.action-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    padding: 1rem 0;
}

.save-button {
    background-color: #1976d2 !important;
    color: white !important;
    padding: 8px 24px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    border-radius: 8px !important;
    transition: all 0.2s ease !important;
}

.save-button:hover {
    background-color: #1565c0 !important;
    box-shadow: 0 4px 12px rgba(25, 118, 210, 0.25) !important;
}

.reset-button {
    border-color: #d32f2f !important;
    color: #d32f2f !important;
    padding: 8px 24px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    border-radius: 8px !important;
    transition: all 0.2s ease !important;
}

.reset-button:hover {
    background-color: rgba(211, 47, 47, 0.04) !important;
    border-color: #c62828 !important;
}

/* Label Styles */
.rental-label {
    font-size: 1.1rem;
    font-weight: 500;
    color: #2c3e50;
    margin-bottom: 0.5rem;
}

/* Stock Count Styles */
.stock-count {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1976d2;
}

/* Loading Spinner */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .rental-card {
        padding: 1rem;
    }

    .action-buttons {
        flex-direction: column;
    }

    .save-button,
    .reset-button {
        width: 100%;
    }
}

/* Animation for success/error messages */
.MuiSnackbar-root {
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

button.btn-pin-code-link:hover {
    color: #d3d3d3;
    transform: scale(1.05);
    background-color: #141414;
    border-color: unset;
}

button.btn-pin-code-link {
    color: #666;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    background-color: #141414;
    border-color: unset;
}

.card-rental-form .card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
    background: unset;
}

.col-cc .MuiInputBase-formControl {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}