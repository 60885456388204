.react-calendar {
    width: 100%;
    background-color: #ffffff;
    color: #1a1a1a;
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

/* Navigation Styles - Improved alignment */
.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0;
    height: 44px;
}

.react-calendar__navigation button {
    color: #1a1a1a;
    min-width: 44px;
    background: none;
    font-size: 1.25rem;
    font-weight: 500;
    border: none;
    padding: 8px;
    margin: 0;
    border-radius: 8px;
    transition: all 0.2s ease;
    touch-action: manipulation;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

/* Month View Styles - Better alignment */
.react-calendar__month-view {
    padding: 0;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.75rem;
    padding: 8px 0;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-around;
}

.react-calendar__month-view__weekdays__weekday {
    flex: 1;
    padding: 8px 0;
    text-align: center;
}

.react-calendar__year-view__months {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
    padding: 0 2px;
}

.react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    padding: 0 2px;
}

/* Tile base styles - Improved grid alignment */
.react-calendar__tile {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    padding: 0 !important;
    margin: 2px;
    border-radius: 50%;
    transition: all 0.2s ease;
    aspect-ratio: 1;
}

button.react-calendar__tile.react-calendar__century-view__decades__decade,
button.react-calendar__tile.react-calendar__decade-view__years__year,
button.react-calendar__tile.react-calendar__year-view__months__month {
    background-color: unset;
    width: 100%;
}

button.react-calendar__tile.react-calendar__century-view__decades__decade:hover,
button.react-calendar__tile.react-calendar__decade-view__years__year:hover,
button.react-calendar__tile.react-calendar__year-view__months__month:hover {
    background-color: rgba(114, 175, 233, 0.219);
}

/* Game Day Styles - More distinct */
.weekend-calendar-games {
    background-color: rgba(25, 118, 210, 0.1);
}

.friday-night-calendar-games {
    background-color: rgba(46, 125, 50, 0.425);
}

.weekend-calendar-games:enabled:hover {
    background-color: rgba(25, 118, 210, 0.2);
}

.friday-night-calendar-games:enabled:hover {
    background-color: rgba(46, 125, 50, 0.2);
}

/* Today's Date Styles */
.react-calendar__tile--now {
    background: rgba(239, 83, 80, 0.322) !important;
    border-radius: 50%;
}

.react-calendar__tile--now:enabled:hover {
    background: rgba(239, 83, 80, 0.3) !important;
}

/* Event Indicator Dot */
.event-indicator {
    width: 6px;
    height: 6px;
    background-color: #1976d2;
    border-radius: 50%;
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
}

/* Selected Date Styles */
.react-calendar__tile--active {
    background: #1976d2 !important;
    color: white !important;
    border-radius: 50%;
}

/* Neighboring Month Days */
.react-calendar__month-view__days__day--neighboringMonth {
    color: #bdbdbd;
}

/* Event List Styles - More modern look */
.event-card {
    background: white;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 12px;
    transition: all 0.2s ease;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.event-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Admin Controls */
.admin-controls {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 16px;
}

.admin-controls button {
    padding: 8px;
    border-radius: 50%;
    transition: all 0.2s ease;
}

@media (max-width: 768px) {
    .react-calendar__tile .MuiSvgIcon-root {
        margin-top: 7px;
        width: 5px;
        height: 5px;
        font-size: 5px;
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .react-calendar {
        padding: 12px;
        margin-bottom: 20px;
    }

    .react-calendar__tile {
        height: 30px;
        margin: 2px;
        font-size: 0.8rem;
    }

    .react-calendar__navigation button {
        padding: 4px;
    }

    .react-calendar__month-view__weekdays {
        padding: 8px 0;
        font-size: 0.7rem;
    }

    /* Improve touch targets */
    .weekend-calendar-games,
    .friday-night-calendar-games,
    .non-event-calendar-days {
        min-width: 30px;
        min-height: 30px;
    }
}