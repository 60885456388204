@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

img {
  max-width: 100%;
  height: auto;
}

.staticBGold {
  background-image: linear-gradient(rgb(27, 26, 26), black);
  height: auto;
}

.staticBG {
  background: url("assets/topbar.png") center no-repeat;
  height: auto;
}

.Homepage {
  background-color: rgb(31, 31, 31);
  text-align: center;
}

.header-row {
  display: inherit !important;
}

.banner {
  position: relative;
  border: 0 !important;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1rem !important;
  letter-spacing: unset !important;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5 !important;
  background-color: rgb(1, 1, 1) !important;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

#root {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

.overlay {
  position: absolute;
  bottom: 0px;
  padding: 5px;
  opacity: .75;
  width: 100%;
  height: 12%;
}

.overlayText {
  position: absolute;
  bottom: 4%;
  left: 20px;
  padding: 5px;
  font-family: Roboto-Black;
  font-size: 1.5vw;
  color: white;
}

.bannerimg {
  object-fit: contain;
  height: auto;
  width: 100%;
  border: 2px solid black;
}

.navbar-all {
  padding: .55rem 1rem !important;
  z-index: 1;
  width: 100%;
  border: 0 !important;
  border-bottom: 1px solid black;
}

.bg-nav {
  background-color: rgba(22, 22, 22, .9) !important;
  border-bottom: 3px solid black;
}

.preheader-parent-div {
  background-color: rgba(16, 36, 65, 1) !important;
}

.preheader-link {
  font-size: 12px;
  padding-left: 1rem;
  text-decoration: none;
}

.preheader-link:hover {
  text-decoration: unset;
}

.logobox {
  width: 75%;
  float: left;
}

.logo {
  max-width: 40% !important;
  min-width: 300px;
}

.loginbox {
  width: 25%;
  float: right;
}

.App-header {
  padding: 20px;
  position: relative;
  width: 100%;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.pagePlaceholder {
  color: white;
  text-align: center;
}


.form-box {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
  border-radius: 5px;
}

.test {
  text-align: center;
}

.profile-style {
  color: white;
}

.imgUpload {
  text-align: center;
}

.imgUpload-text {
  color: white;
}

.imgUpload-submit {
  margin-top: 10px;
}

.imgUpload-input {
  background-color: grey;
  text-align: left;
  border-radius: 5px;
}

.img-upload-col-button {
  max-width: 380px !important;
  justify-content: flex-end;
  display: flex;
}

.notice-col-settings {
  max-width: 380px !important;
  justify-content: center;
  display: flex;
}

.pdfStyle {
  text-align: center;
  color: white;
}

.mapStyle {
  text-align: center;
  color: white;
}

.logout {
  justify-content: center;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.login {
  justify-content: center;
}

.welcome {
  color: white;
  margin-bottom: 4px;
  text-align: center;
}

.pdfFile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.forgotP {
  color: white;
  text-align: center;
  padding-bottom: 10px;
}

.input-group-forgot-pass {
  padding-bottom: 10px;
}

.forgotPass {
  padding-top: 10px;
  text-align: end;
}

.team-rows {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}

.team-rows-icons {
  font-size: 1.4em !important;
  display: inline-block;
}

.team-name {
  vertical-align: middle !important;
  color: white !important;
  font-size: .6rem;
  text-align: center;
}

.link-team-name {
  vertical-align: middle !important;
  color: white !important;
  text-align: center;
}

.team-info {
  justify-content: center;
  align-items: center;
}

.profile-link {
  color: white;
}

.form-container {
  margin: 0 auto;
  padding: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  padding: 0.75rem;
  border-radius: 8px;
}

.signature-container {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  width: 100%;
}

.signature-pad-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 200px;
  position: relative;
  margin-bottom: 1rem;
}

.signature-pad-container canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.signature-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .signature-pad-container {
    height: 150px;
  }
}

.saved-signature {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 10px;
  border-radius: 4px;
}

.saved-signature img {
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.team-icon-individual {
  border: 1px solid grey;
}

.team-single-img {
  justify-content: center;
  align-items: center;
  display: flex;
}

.padding-2px {
  padding: 2px;
}

.team-icon-teamspage {
  position: relative;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid grey;
}

.description-teamspage {
  text-align: center;
  width: 80%;
}

.description-row-teamspage {
  margin-right: 0px !important;
  margin-left: 0px !important;
  justify-content: center;
}

.members-row-teamspage {
  margin-right: 0px !important;
  margin-left: 0px !important;
  border-radius: 5px;
  justify-content: center;
  margin-bottom: 30px;
}

div .members-textbox-teamspage {
  text-align: center;
  color: black;
  border-radius: 5px;
  background-color: white;
  width: 80%;
  margin-left: 15px;
  margin-right: 15px;
}

.admin-cards .div-child-admin {
  display: flex;
}

.admin-cards .MuiTableCell-head {
  color: white;
}

.admin-cards .MuiIconButton-root {
  color: white;
}

.selected-admin {
  background-color: rgba(44, 57, 74, .9);
}

.appbar-opened-admin {
  width: calc(100% - 240px);
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  margin-left: 240px;
}

.main-div-admin-drawer {
  padding: 24px;
  flex-grow: 1;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.87);
}

.div-child-admin {
  display: flex;
}

@media (min-width: 1400px) {
  .background-static-admin .container {
    max-width: 1140px;
  }
}

.iconbutton-opened-admin {
  visibility: hidden;
}

.spacer-div-admin-drawer {
  display: flex;
  padding: 0px 8px;
  min-height: 56px;
  align-items: center;
  justify-content: flex-end;
}

.div-parent-admin .MuiDrawer-docked {
  flex-shrink: 0;
  white-space: nowrap;
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 73px;
}

.div-parent-admin .MuiDrawer-paper {
  width: 73px;
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.div-parent-admin .MuiAppBar-positionFixed {
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.appbar-opened-admin.MuiAppBar-positionFixed {
  width: calc(100% - 240px);
  margin-left: 240px;
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  z-index: 1201;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: rgba(22, 22, 22, .9);
}

.drawer-opened-admin.MuiDrawer-docked {
  width: 240px;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.drawer-opened-admin .MuiDrawer-paper {
  width: 240px;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.waiver-row-rp .waiver-rp {
  width: unset;
  border-radius: 2px;
  padding: unset;
}

.waiver-row-rp .row>* {
  width: unset;
  max-width: unset;
}

.text-block-waiver-rp.row {
  width: unset;
  margin-top: 5px;
}

h2.waiver-header-rp {
  width: unset;
}

form.waiver-form-rp {
  width: unset;
}

button.clear-button-rp.btn.btn-secondary {
  width: unset;
}

button.save-button-rp.btn.btn-secondary {
  width: unset;
}

button.prev-button-rp.btn.btn-info {
  width: unset;
}

button.next-button-rp.btn.btn-info {
  width: unset;
}

p.error-text-rp {
  width: unset;
}

.usertable-pagination-navigation-admin {
  flex-shrink: 0;
  margin-left: 20px;
}

.row-subscribe-email-rp .col {
  width: unset;
  justify-content: center;
  display: flex;
}

.selected-row-admin-users .MuiTableCell-body {
  background-color: rgba(44, 57, 74, .9);
}

.admin-cards .MuiPaper-root {
  padding: unset;
}

.selected-row-admin-users .MuiTableCell-body.MuiTableCell-sizeSmall {
  color: rgba(255, 255, 255, .9);
  border-bottom: unset;
  background-color: rgba(141, 141, 141, .1);
}

.main-admin-users-table .selected-row-admin-users .MuiTableCell-root {
  border-bottom: unset;
}

.main-admin-users-table .MuiTableCell-body.MuiTableCell-sizeSmall p {
  display: inherit;
  padding: 10px;
  font-size: 0.875rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-shadow: 1px 1px #000000;
  border-radius: 15px;
  text-transform: uppercase;
  background-color: rgba(2, 231, 40, .24);
}

.main-admin-users-table-container {
  margin-top: 1rem !important;
}

.expired-p-usertable {
  background-color: rgba(251, 35, 55, .85) !important;
}

.main-admin-users-table .MuiBox-root {
  margin: 8px;
}

.row-buttons-uo .btn {
  width: unset;
}

.modal-pincode {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-transaction-rf h5 {
  width: unset;
}

.div-back-button-rf .MuiButtonBase-root {
  width: unset;
}

.row-group-name-rf .h5-group-name-rf {
  width: unset;
}

.row-group-name-rf .MuiButtonBase-root {
  width: unset;
}

.row .div-email-rf {
  width: unset;
}

.header-profile {
  color: white;
}

.left-column-profile {
  margin-top: 0px;
}

.team-members-box {
  margin-bottom: 30px;
  padding: 5px;
}

.team-manage-text {
  color: white;
}

.team-manage-blank {
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.team-manage-button {
  margin-top: 10px;
}

.team-manage-button-2 {
  margin-top: 10px;
  margin-left: 5px;
}

.page-header {
  font-size: 1.5rem;
  color: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.team-link {
  align-items: center;
  justify-content: center;
  color: black;
}

.team-member-list {
  text-align: center;
  width: 80%;
}

.team-upload {
  color: white !important;
}

.progress {
  width: 100%;
}

.upload-button {
  margin-top: 10px;
}

.team-pictures {
  border: 1px solid #969696;
  max-width: 150px;
}

.team-upload-warning {
  margin-top: 10px;
  margin-left: 10px;
  color: white;
}

.team-upload-text {
  color: white !important;
  margin-bottom: 10px !important;
  padding-top: 10px !important;
}

.next-button {
  margin-top: 10px;
}

.previous-button {
  margin-top: 10px;
}

.submit-button {
  margin-top: 10px;
  margin-left: 15px;
}

.error-team-create {
  color: red;
}

.wins-lb {
  color: rgb(118, 251, 0) !important;
}

.losses-lb {
  color: #fb6060 !important;
}

.profilelink-lb {
  width: 50%;
}

.myprofilelink-lb:link {
  color: gold;
}

.myprofilelink-lb:visited {
  color: gold;
}

.profilelink-lb:link {
  color: white;
}

.profilelink-lb:visited {
  color: white;
}

.profilelink-tm:link {
  color: black;
}

.profilelink-tm:visited {
  color: black;
}

.team-link:hover {
  color: black;
}

.team-link:visited {
  color: black;
}

.table-lb {
  opacity: .8 !important;
}

.header-lb {
  border-bottom: 1px solid rgb(255, 217, 0, .5);
  background-color: rgb(36, 36, 36);
  font-weight: 400;
  text-align: center;
}

.tbody-lb {
  font-weight: 300;
}

.td-lb {
  vertical-align: middle !important;
  padding: .5rem .1rem !important;
  text-align: center;
}

.td-tv-lb {
  vertical-align: middle !important;
  padding: 0 .1rem !important;
  text-align: center;
}

.link-td-profile-lb {
  color: gold;
  vertical-align: middle;
}

.link-td-profile-lb:hover {
  color: gold;
  vertical-align: middle;
}

.link-td-lb {
  color: white;
  vertical-align: middle;
  text-decoration: none;
}

.link-td-lb:hover {
  color: white;
  vertical-align: middle;
  text-decoration: underline;
}

.team-info .team-info-first-div {
  width: unset;
}

.standard-navigation-buttons-row-pricing .col-md-2 {
  justify-content: center;
  display: flex;
}

.standard-navigation-buttons-row-pricing .MuiButton-root {
  background: rgb(8, 37, 68);
  box-shadow: rgb(192 192 192 / 30%) 0px 1px 1px 1px;
  color: white;
  border: 0;
  height: 48px;
  margin: 8px;
  padding: 0 20px;
  border-radius: 30px;
}



.justify-content-flex-end-row {
  justify-content: flex-end;
  padding-right: 15px;
}

.justify-content-flex-end-col {
  justify-content: flex-end;
  display: flex;
}

.page-link {
  background-color: rgb(172, 172, 172, .7) !important;
  border: none !important;
  color: black !important;
}

.page-item.active .page-link {
  background-color: rgb(0, 123, 255, .9) !important;
}

.Ripple.is-reppling {
  animation: none !important;
}

.header-th-lb {
  border-bottom: 1px solid rgb(255, 217, 0, .5) !important;
  border-bottom-width: 1px !important;
  text-align: center;
}

.name-header-th-lb {
  border-bottom: 1px solid rgb(255, 217, 0, .5) !important;
  border-bottom-width: 1px !important;
}

.header-th-tlb {
  border-bottom: 1px solid rgb(255, 217, 0, .5) !important;
  border-bottom-width: 1px !important;
  text-align: left;
}

.knocked-out-tr-tlb {
  background-color: rgb(255 0 0 / 20%) !important;
}

.button-left-lb {
  padding: 10px 15px;
}

.button-right-lb {
  padding: 10px 15px;
}

.toggled-lb .switch-handle {
  background-color: #343a40;
  border-color: #343a40;
}

.not-toggled-lb .switch-handle {
  background-color: #fff;
  border-color: #fff;
}

.title-lb {
  padding-top: 20px;
}

.background-static-lb::before {
  background-color: black;
}

.background-static-all::before {
  background-color: rgb(0, 0, 0);
}

.background-static-rp::before {
  background-color: rgb(0, 0, 0);
}

.password-text {
  color: white;
}

.profile-img-settings {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.button-create-teams {
  float: left;
}

.button-manage-teams {
  float: right;
}

.dropdown-item.active {
  color: white !important;
  background-color: rgb(4, 4, 4, .4) !important;
  border-radius: 5px;
  /* box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15); */
  position: relative;
}

.dropdown-item:active {
  background-color: rgb(4, 4, 4, .4) !important;
  border-radius: 5px;
  /* box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15); */
}

.dropdown-item:hover {
  color: white !important;
  background-color: rgb(4, 4, 4, .2) !important;
  border-radius: 5px;
  /* box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15); */
  transition: all .1s linear;
  position: relative;
}

.notice-text-settings {
  color: white;
  margin-bottom: 0;
}

.col-settings {
  min-width: initial !important;
  margin-bottom: 20px;
}

.about-us {
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
}

.header-about-us {
  font-size: 1.5rem;
  margin-bottom: .5rem;
  color: white;
  font-weight: 300;
  text-align: center;
}

.h5-hours-subheader {
  font-size: 1.2rem;
  padding-bottom: 0px;
  font-weight: 300;
  color: white;
  text-align: center;
}

.dt-hours-info {
  font-size: 11px;
  font-weight: 300 !important;
  color: white;
}

.p-hours-season {
  color: white !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px;
}

.col-hours-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-hours {
  text-align: left;
  display: flex;
  justify-content: center;
}

.dl-hours-info {
  text-align: center;
}

.p-hours-season-month {
  color: white !important;
  font-style: normal !important;
  font-weight: bold;
  font-size: 10px;
}

.video-box-home {
  padding-bottom: 18px !important;
  overflow: hidden;
}

.hours-home {
  width: 100%;
  margin-right: 15px;
  margin-left: 15px;
}

.hours-row-home {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.boxes-home {
  background-color: rgb(255, 255, 255, .2) !important;
  color: white;
  margin-bottom: 15px;
}

.boxes-home-nopadbot {
  padding-bottom: 0 !important;
}

.small-logo-home {
  height: auto;
  width: 25%;
  margin: 0;
}

.small-logo-home2 {
  height: auto;
  width: 25%;
  margin: 0;
}

.login-button-nav {
  padding: 2px !important;
}

.logout-button-nav {
  padding: 2px !important;
}

.text-profile {
  color: black;
}

.img-input-teamc {
  margin-top: 10px;
}

.header-teamc {
  color: white;
  text-align: center;
  padding-top: 10px;
}

.row-buttons-teamc {
  margin-left: 0 !important;
}

.row-members-teams {
  margin-bottom: 30px;
  justify-content: center;
}

.h2-description-team {
  font-size: 16px;
  margin: 10px;
  overflow-wrap: break-word;
}

.status-card-body-wl-admin {
  color: white;
  padding-bottom: 0 !important;
  padding-top: 5px !important;
  background-color: rgb(36, 36, 36);
}

.status-card-body-fg-admin {
  overflow-y: scroll;
  max-height: 40vh;
  color: white;
  padding-bottom: 0 !important;
  padding-top: 5px !important;
}

.status-card-body-main-admin {
  overflow-y: scroll;
  max-height: 50vh;
  color: white;
  padding-bottom: 0 !important;
  padding-top: 5px !important;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.status-card-body-admin {
  overflow-y: scroll;
  max-height: 40vh;
  color: white;
}

.status-card-body-renew-admin {
  overflow-y: scroll;
  max-height: 40vh;
  color: white;
  padding-bottom: 0 !important;
  padding-top: 5px !important;
}

.status-card-offrow-admin {
  background-color: darkgray;
  margin-left: -10px;
  padding: 10px;
  color: white;
  border-radius: 5px;
}

.status-card-row-admin {
  color: white;
}

.status-card-offrow-admin-fg {
  background-color: darkgray;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.status-card-offrow-admin-wl {
  background-color: darkgray;
  padding-bottom: 10px;
  padding-top: 10px;
}

.row-fg {
  margin-bottom: 10px;
}

.col-name-fg {
  display: flex;
  align-items: center;
}

.button-submit-admin {
  margin-top: 1rem;
}

.button-submit-admin2 {
  float: right;
}

.form-input-admin {
  width: 20rem !important;
}

.status-card-admin {
  margin-top: 15px;
}

.center-navbar-nav {
  margin-right: 45px !important;
}

.iframe-home {
  display: block;
  width: 100%;
}

.search-label-admin {
  color: white;
}

.col-admin-mg {
  display: flex;
  justify-content: center;
}

.header-rp {
  align-items: center;
  justify-content: center;
}

.button-row-rp2 {
  justify-content: center;
  background-color: transparent !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-rp {
  color: white;
}

.waiver-form-rp {
  color: white;
}

.row-rp {
  justify-content: center;
  background-color: rgb(108, 117, 125, 0.75);
  padding: 0 15px;
}

.spinner-row-rp {
  padding: 5px;
}

.row-renew {
  justify-content: center;
  padding: 0 15px;
}

.col-waiver {
  background-color: rgb(108, 117, 125, 0.75);
}

.row-buttons-admin {
  padding-right: 15px;
  padding-left: 15px;
}

.row-success-rp {
  justify-content: center;
  background-color: rgb(73 80 87 / .6);
  border-radius: 5px;
  width: 80%;
}

.cardpreview-row-rp {
  justify-content: center;
  align-items: center;
}

.button-row-rp {
  float: right;
}

.card-rp {
  max-width: 50% !important;
  margin: 0;
}

.waiver-rp {
  justify-content: center;
  transition: display 1s, opacity 0.5s linear;
  opacity: 1;
}

.waiver-hidden-rp {
  transition: display 1s, opacity 0.5s linear;
  opacity: 0;
  display: none;
}

.waiver-input-rp {
  margin-top: 50px !important;
  color: white;
}

.button-hidden-rp {
  color: white !important;
}

.nav-row-rp {
  margin-top: 10px;
  justify-content: center;
  margin-bottom: 10px;
}

.nav-row-rp1 {
  margin-top: 100px;
}

.next-button-rp {
  margin-left: 5px;
}

.prev-button-rp {
  margin-right: 5px;
}

.waiver-row-rp {
  position: relative;
}


.waiver-header-rp {
  justify-content: center;
  color: white;
}

.text-block-waiver-rp {
  position: absolute;
  color: white;
  max-width: 766px;
  min-width: 766px;
  margin-top: 5px;
  margin-left: 45px !important;
}

.card-row-rp {
  position: relative;
}

.text-block-card-rp {
  position: absolute;
  color: white;
  top: 245px;
  max-width: 236.5px;
  min-width: 236.5px;
  background-color: darkgray;
  justify-content: center;
  padding: 5px;
}

.text-block-empty-rp {
  position: absolute;
  color: white;
  top: 245px;
  max-width: 236.5px;
  min-width: 236.5px;
  background-color: darkgray;
  justify-content: center;
  padding: 0px;
}

.participant-sig-rp {
  background-color: white;
  width: 100%;
  height: 150px;
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.sig-row-rp {
  justify-content: center;
  background-color: transparent !important;
  position: relative;
  margin: auto;
  align-items: center;
}

.sig-col-rp {
  height: 150px;
}

.pg-sig-rp {
  background-color: white;
}

.header-sig-rp {
  text-align: center
}

.signBox-image-rt {
  background-color: white;
  padding: 20px;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  height: 150px;
}

.clear-button-rp {
  margin-right: 5px;
  border: 1px solid black !important;
}

.save-button-rp {
  margin-left: 5px;
  border: 1px solid black !important;
}

.divider-row-rp {
  justify-content: center;
  content: '';
  display: block;
  width: 70%;
  height: 5px;
  border-radius: 5px;
  background-color: darkgrey;
  margin: auto;
}

.error-text-rp {
  color: red;
}

.text-fow {
  color: white;
}

.img-map img {
  max-width: none !important;
}

.col-rp {
  align-items: center;
}

.row-allwaivers-wl {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 40vh;
  margin-left: -15px;
  margin-right: -15px;
  padding: 5px;
}

.card-header-wl {
  padding: 5px;
  border-bottom: 2px solid rgb(255, 217, 0, .5);
  font-weight: bold;
}

.row-wl {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(108, 117, 125, 0.75);
}

.justify-content-center-col {
  display: flex;
  justify-content: center;
}

.admin-header {
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.admin-cards {
  color: white;
  background-color: rgb(108, 117, 125, 0.75) !important;
  min-height: 100%;
  border: none !important;
}

.waiver-cards {
  color: white;
  background-color: transparent !important;
  min-height: 100%;
  border: none !important;
}

.admin-card-icon {
  text-align: right;
}

.admin-card-icon2 {
  text-align: left;
}

.admin-card-footer:hover {
  text-decoration-line: underline !important;
  text-decoration-color: white !important;
}

.admin-card-header-link {
  background-color: rgb(4, 4, 4, .4) !important;
  color: #ffffff !important;
}

.card-text {
  color: white;
}

.status-card-body-wl-admin .spinner-border {
  color: white;
}

.admin-card-footer {
  padding-left: 0px !important;
  padding-right: 0px !important;
  box-sizing: content-box;
}

.admin-cards-link:hover {
  text-decoration: none;
}

.admin-row-email {
  margin-top: 0px;
}

.admin-col-cards {
  min-height: 100%;
  margin-bottom: 20px;
}

.admin-button-email1 {
  font-size: .7rem !important;
  margin-right: 5px;
}

.admin-button-email2 {
  font-size: .7rem !important;
  margin-left: 5px;
  margin-right: 5px;
}

.admin-button-email3 {
  font-size: .7rem !important;
  margin-left: 5px;
}

.admin-row-points {
  background-color: rgb(108, 117, 125, 0.75);
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.admin-col-button-points {
  align-items: center;
  display: flex;
}

.admin-breadcrumb ol {
  background-color: transparent !important;
  color: white !important;
}

.admin-breadcrumb li {
  color: white !important;
}

.admin-breadcrumb a {
  color: white !important;
  text-decoration: underline;
}

.admin-breadcrumb a:link:hover {
  color: lightgray !important;
  text-decoration: none;
}

.confirm-buttons-admin {
  margin: 0 !important;
}

.col-options-button-admin {
  justify-content: left;
  display: flex;
  margin-bottom: 5px;
}

.options-buttons-row-admin {
  background: none !important;
}

.user-card-offrow-admin-fg {
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: darkgray;
  margin-left: -10px;
  padding-left: 10px;
}

.row-users-card-offrow-admin {
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: lightslategray;
}

.row-users-card-offrow-active-admin {
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: black;
}

.row-users-card-offrow-admin:hover {
  transition: background-color 1.2s ease;
  background-color: black;
  border-radius: 5px;
}

.row-users-card-admin {
  margin-bottom: 10px;
}

.row-users-card-active-admin {
  margin-bottom: 10px;
  background-color: black;
  border-radius: 5px;
}

.row-users-card-admin:hover {
  transition: background-color 1.2s ease;
  background-color: black;
  border-radius: 5px;
}

.row-options-admin {
  margin-top: -12px;
  margin-bottom: 5px;
  padding: 5px 5px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  border: 2px solid black;
  border-radius: 5px;
}

.button-options-style-admin {
  padding: .25rem !important;
  float: right;
  white-space: nowrap;
}

.col-name-ul {
  display: flex;
  align-items: center;
  min-width: 100%;
}

.button-options-col-admin {
  padding: 0 2px !important;
}

.loading-text-ul-admin {
  justify-content: center;
}

.status-ul-admin {
  color: lightgreen;
  text-align: center;
  margin-top: -15px;
}

.error-ul-admin {
  color: red;
  text-align: center;
  margin-top: -15px;
}

.status-email-admin {
  color: lightgreen;
  text-align: left;
  padding-top: 5px;
}

.email-img-admin {
  position: absolute;
  top: 30%;
  left: 5%;
  max-width: 90%;
  height: auto;
}

.email-body-form-admin {
  text-align: center;
}

.admin-row-emailoptout {
  background-color: rgb(73 80 87 / .6);
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-radius: 5px;
  padding: 10px;
  color: white;
}

.container-emailoptout {
  padding-top: 20px;
}

.status-oo-admin {
  color: lightgreen;
  text-align: center;
  margin-left: 5px;
}

.error-oo-admin {
  color: red;
  text-align: center;
  margin-top: 1rem;
}

.navitem-img {
  max-width: 320px;
}

.row-uo {
  color: white;
}

.row-buttons-uo {
  direction: rtl;
  padding: 15px;
}

.button-update-uo {
  margin-right: 15px;
}

.row-priv-buttons-uo {
  background-color: gray;
  padding: 15px;
  border-radius: 5px;
}

.super-status-uo-admin {
  color: lightgreen;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.status-uo-admin {
  color: lightgreen;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  margin-right: 10px;
  align-items: center;
  display: flex;
}

.status-col-uo {
  align-content: center;
  display: grid;
  justify-content: end;
}

.row-header-lb {
  padding-top: 10px;
  margin-bottom: unset;
  justify-content: center;
}

.row-header-tv-lb {
  padding-top: 10px;
  margin-bottom: -20px;
}

.col-header-lb {
  align-items: center;
  display: flex;
}

.vertical-divider-col-lb {
  border-right: 1px solid white;
}

.col-dropdown-months-lb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.row-pagination-lb {
  position: relative;
}

.row-pagination-lb .pagination-col-lb {
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: flex-end;
  /* margin-bottom: -8px;
  margin-top: 8px; */
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
}

.pagination-col-lb .MuiPaginationItem-outlined {
  color: rgb(222 222 222);
  border: 1px solid rgb(210 210 210 / 23%);
  background-color: rgb(73 80 87 / .6);
}

.pagination-col-lb .MuiPagination-root {
  right: unset;
}

.pagination-col-lb .MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
}

.pagination-col-lb .MuiPaginationItem-outlinedPrimary.Mui-selected:hover,
.MuiPaginationItem-outlinedPrimary.Mui-selected.Mui-focusVisible {
  background-color: rgb(73 80 87 / .6);
}

.pagination-col-lb .MuiPaginationItem-ellipsis {
  color: white;
  border: unset;
  background-color: unset;
}

.div-team-leader-teams {
  display: unset;
  padding: 16px;
}

.img-team-member-teams {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.img-crown-teams {
  width: 15px;
  height: 15px;
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
}

.row-header-teams {
  padding-top: 15px;
  padding-bottom: 15px;
}

.col-options-dropdown-teams .MuiListItem-root.Mui-selected,
.col-options-dropdown-teams .MuiListItem-root.Mui-selected:hover {
  background-color: rgb(73 80 87 / .2);
  font-weight: 700;
}

.col-options-dropdown-teams .MuiListItem-button:hover {
  background-color: rgb(73 80 87 / .2);
}

.col-options-dropdown-teams .MuiButton-containedPrimary {
  background-color: transparent;
  color: rgba(255, 255, 255, .5);
}

.col-options-dropdown-teams .MuiButton-containedPrimary:hover {
  background-color: transparent;
  color: white;
}

.col-options-dropdown-teams .MuiButton-text {
  color: rgba(255, 255, 255, .5);
}

.col-options-dropdown-teams .MuiButton-label {
  font-weight: 300;
  font-size: 20px;
}

.col-options-dropdown-teams .MuiButton-root {
  font-family: unset;
  font-size: 20px;
  font-weight: 300;
}

.pagination-row-user-table .MuiTablePagination-selectLabel {
  color: white;
  margin-bottom: unset;
}

.pagination-row-user-table .MuiTablePagination-displayedRows {
  color: white;
  margin-bottom: unset;
}

.pagination-row-user-table .MuiNativeSelect-select {
  color: white;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiNativeSelect-icon.MuiTablePagination-selectIcon.MuiNativeSelect-iconStandard.css-10bey84-MuiSvgIcon-root-MuiNativeSelect-icon {
  color: white;
}

.pagination-row-user-table .MuiTablePagination-menuItem {
  color: black;
}

.col-textfield-left-rf .MuiFormLabel-root {
  color: white !important;
}

.col-textfield-right-rf .MuiFormLabel-root {
  color: white !important;
}

.col-textfield-left-rf .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.col-textfield-right-rf .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.col-textfield-left-rf .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.col-textfield-right-rf .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.col-textfield-rf .MuiOutlinedInput-input {
  color: white;
}

.col-textfield-left-rf .MuiFocused {
  color: white;
  border-color: white;
}

.table-cell-button-rf .MuiButton-containedSecondary {
  background-color: #f50057;
}

.col-options-dropdown-teams .MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
  border: none;
}

.col-options-dropdown-teams a:hover .MuiButton-text {
  color: white;
  text-decoration: none;
}

.col-options-dropdown-teams a:hover {
  text-decoration: none;
}

.p-header-teams {
  font-size: 24px;
  font-weight: 300;
  color: white;
  border-right: 1px solid #595959;
  margin-bottom: 0 !important;
  padding-right: 30px;
}

.row-navigation-teams {
  margin-bottom: 15px;
}

.pagination-col-teams {
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: flex-end;
  /* margin-bottom: -8px;
  margin-top: 8px; */
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
}

.pagination-col-teams .MuiPaginationItem-outlined {
  color: rgb(222 222 222);
  border: 1px solid rgb(210 210 210 / 23%);
  background-color: rgb(73 80 87 / .6);
}

.pagination-col-teams .MuiPagination-root {
  right: unset;
}

.pagination-col-teams .MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
}

.pagination-col-teams .MuiPaginationItem-outlinedPrimary.Mui-selected:hover,
.MuiPaginationItem-outlinedPrimary.Mui-selected.Mui-focusVisible {
  background-color: rgb(73 80 87 / .6);
}

.pagination-col-teams .MuiPaginationItem-ellipsis {
  color: white;
}

.img-row-contact {
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.img-contact {
  border-radius: 5px;
  min-width: 100%;
}

.text-col-contact {
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
  background-color: rgb(73 80 87 / .6);
  margin: 10px;
  border-radius: 5px;
}

.row-contact {
  justify-content: center;
}

.text-row-contact {
  padding: 10px;
}

.about-col-left-footer {
  color: white;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}

.about-col-right-footer {
  color: white;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}

.myaccount-col-footer {
  color: white;
  font-size: 14px;
}

.all-cols-footer {
  justify-content: center;
  display: grid;
}

.socials-icons-footer {
  padding: 5px;
}

.socials-icons-row {
  justify-content: center;
}

.socials-icons-col {
  justify-content: center;
  display: flex;
}

.profile-row-footer {
  justify-content: center;
}

.profile-icon-stats {
  color: black;
  margin-bottom: 15px;
}

.p-empty-rentals-rf {
  color: white;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.icon-editing-rf {
  font-size: 1.5rem;
  color: white;
}

.icons-rf {
  font-size: 1.5rem;
}

.col-form-rf {
  padding-top: 10px;
}

.col-add-card-button-rf {
  display: flex;
  align-items: center;
  padding-top: 25px;
}

.error-text-rental-rf {
  color: red;
}

.col-submit-rf {
  align-items: center;
  display: flex;
}

.col-submit-rf .MuiButton-contained.Mui-disabled {
  color: rgba(256, 256, 256, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.cancel-button-rf {
  margin-left: 15px !important;
}

.div-buttons-rf .MuiButton-containedPrimary {
  background-color: #008e2b
}

.div-buttons-rf .MuiButton-containedPrimary:hover {
  background-color: #005c1c
}

.modal-ef {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper-ef {
  background-color: rgb(73 80 87 / .9);
  border: 2px solid #000;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 5px;
}

.root-ef {
  width: 100%;
}

.heading-ef {
  font-size: 0.9375rem !important;
  flex-basis: 33.33%;
  flex-shrink: 0;
  color: white;
  white-space: nowrap;
}

.secondaryHeading-ef {
  font-size: 0.9375rem !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.link-footer:link {
  color: white;
}

.link-footer:visited {
  color: white;
}

.link-footer {
  padding: 0 10px;
  font-weight: 300;
}

.col-link-footer {
  display: flex;
  justify-content: center;
}

.col-link-footer-right {
  display: flex;
  justify-content: center;
}

.row-header-footer {
  justify-content: center;
}

.col-nonauth-footer {
  display: contents;
}

.status-row-footer {
  color: lightgreen;
  justify-content: center;
}

.about-div-home {
  margin-top: 35px;
}

.notice-text-container {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.row-notice {
  justify-content: center;
  align-items: center;
}

.notice-text {
  color: red;
  text-align: center;
}

.notice-text-g {
  color: green;
  text-align: center;
}

.form-label-login {
  float: left;
}

.login-container {
  padding-top: 20px;
  max-width: 565px !important;
}

.login-col {
  background-color: rgb(73 80 87 / .6);
  border-radius: 5px;
}

.login-form {
  color: white;
}


@media (min-width: 1200px) {
  .login-form {
    margin-left: 20%;
    margin-right: 20%;
  }
}

.small-logo-login {
  height: auto;
  width: 35%;
  margin: 0;
}

.italic-forgot-text {
  color: gray;
}

.password-forgot-button {
  float: right;
}

.password-change-form {
  color: white;
  display: contents;
}

.dropdown-waiverlookup {
  max-height: 30vh;
  overflow-y: scroll;
}

.tooltip {
  transition: opacity 0.15s linear;
}

.file-field {
  display: flex;
  justify-content: center;
}

.col-teampage {
  display: flex;
  justify-content: center;
  text-align: center;
}

.container-teampage {
  overflow-x: hidden;
}

.container-instagram {
  overflow-x: hidden;
}

.set-width-100 {
  min-width: 80%;
  margin-bottom: 30px;
}


.table-lb {
  font-size: 11px;
}

.page-header-lb {
  font-size: 1rem;
}

.rank-image-lb {
  max-width: 40%;
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

.firstPlace {
  font-family: sans-serif;
  color: gold;
  margin-bottom: 0;
  font-size: 17px;
}

.secondPlace {
  font-family: sans-serif;
  color: silver;
  margin-bottom: 0;
  font-size: 15px;
}

.thirdPlace {
  font-family: sans-serif;
  color: #CD7F32;
  margin-bottom: 0;
  font-size: 13px;
}

.background-static-lb {
  /* background: url("assets/background-mobile.jpg") center no-repeat; */
  background: linear-gradient(174deg, rgba(35, 38, 38, 0.7511379551820728) 25%, rgba(0, 0, 0, 0.8547794117647058) 72%);
  background-size: cover;
  background-position: 50% 50%;
  object-fit: cover;
  color: white;
  min-height: 100vh;
  overflow-x: hidden;
}

.background-static-all {
  /* background: url("assets/background-mobile.jpg") center no-repeat; */
  background-size: cover;
  background-position: 50% 50%;
  object-fit: cover;
  min-height: 100%;
}

.background-static-rp {
  /* background: url("assets/background-mobile.jpg") center no-repeat; */
  background: linear-gradient(174deg, rgba(35, 38, 38, 0.7511379551820728) 25%, rgba(0, 0, 0, 0.8547794117647058) 72%);
  background-size: cover;
  background-position: 50% 50%;
  object-fit: cover;
  min-height: 100%;
}

.topdiv-footer {
  /* background-color: rgba(22,22,22,0.9);
  border-top: 1px solid darkgray; */
  padding: 20px;
}

.row-title-about {
  justify-content: center;
}

.p-header-about {
  color: white;
  font-size: 24px;
  font-weight: 300;
}

.p-description-about {
  color: white;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
}

.row-description-about {
  justify-content: center;
}

.input-group-footer {
  margin-top: .5rem;
  width: 100% !important;
}

.divider-footer {
  justify-content: center;
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background-color: darkgrey;
  margin: auto;
  margin-top: 10px;
}

.about-col-left-footer {
  color: white;
  font-size: 9px;
  margin-left: 10px;
  margin-right: 10px;
}

.about-col-right-footer {
  color: white;
  font-size: 9px;
  margin-left: 10px;
  margin-right: 10px;
}

.table-teams {
  margin-bottom: 0 !important;
}

.row-loading-imgup {
  justify-content: center;
  padding: 5px;
}

.p-status-imgup {
  color: green;
}

.row-loading-passch {
  justify-content: center;
  padding: 5px;
}

.p-status-passch {
  color: green;
}

.passch-row-button {
  justify-content: flex-end;
  flex: 1;
}

.justify-content-row {
  justify-content: center;
}

.justify-content-center-link {
  justify-content: center;
  display: flex;
}

.padding-5px {
  padding: 5px;
  color: white;
}

.col-cc {
  padding-top: 10px;
}

.row-cc {
  padding-bottom: 10px;
}

.textfield-zip-cc {
  width: 100%;
}

.div-loading-profile {
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
}

.col-team-user {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.row-update-rules {
  justify-content: flex-end;
  padding-right: 30px;
  color: white;
  font-style: italic;
}

.ol-rules {
  color: white;
}

.tab-content {
  background-color: rgb(73 80 87 / .6);
  padding: 0 5px;
  padding-top: 15px;
  border-radius: 5px;
}

.li-content-rules {
  padding-bottom: 15px;
  margin-right: 5px;
}

.status-text-teammanage {
  color: green;
}

.row-nomargin {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.header-footer {
  color: white;
  margin-bottom: 0 !important;
  font-size: 1rem;
}

.spinner-standard {
  justify-content: center;
  display: flex;
  padding: 10px;
}

.left-row-hours {
  display: table-cell !important;
  padding-left: 30px;
}

.right-row-hours {
  display: table-cell !important;
}

.last-row-hours {
  display: inline-block !important;
}

*:focus {
  outline: none !important;
}

.text-align-center {
  text-align: center;
}

.dd-text-pricing {
  margin-right: 5px;
}

.card-body-birthday-pricing {
  justify-content: center;
  display: flex;
  align-items: center;
}

.col-left-nav-button-pricing {
  justify-content: flex-end;
  display: flex;
}

.col-left-nav-button-pricing .btn {
  padding: 0.175rem 0.75rem;
}

.col-right-nav-button-pricing {
  justify-content: flex-start;
  display: flex;
}

.col-right-nav-button-pricing .btn {
  padding: 0.175rem 0.75rem;
}

.MuiButton-label {
  text-shadow: black 0.1em 0.1em 0.2em;
  font-weight: bold;
}

.text-red {
  color: red;
}

.col-pricing {
  margin-bottom: 15px;
  color: black;
}

.card-pricing {
  min-height: 100%;
}

.row-fade-pricing {
  position: absolute
}

.buttons-pricing {
  margin: 0 5px;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.row-buttons-pricing {
  margin-bottom: 10px;
}

.card-body-other-pricing {
  display: grid;
  justify-content: center;
}

.list-gametypes {
  height: 60vh;
  overflow-y: scroll;
}

.tab-content>.active {
  overflow: auto;
  height: 70vh;
}

.dropdown-menu.show {
  padding: .5rem;
  visibility: visible;
  opacity: 1;
}

.dropdown-menu {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity .2s linear;
  display: block !important;
}

.dropdown-item {
  padding-left: .55rem !important;
  border-radius: 5px;
}

.p-rank-leaderboard {
  margin-left: 2px;
  margin-bottom: 0;
}

.img-instagram {
  margin-bottom: 30px;
}

.row-instagram {
  justify-content: center;
  position: absolute;
  bottom: 30px;
  color: white;
  margin-left: 0px !important;
  margin-right: 15px !important;
  width: 100%;
}

.col-photo-instagram .p-caption-instagram {
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.col-photo-instagram:hover .p-caption-instagram {
  opacity: 1;
  background-color: rgb(4, 4, 4, .5);
}

.p-caption-instagram {
  margin-right: 30px;
  padding: 5px;
  width: 100%;
  text-align: center;
}

.instagram-logo {
  padding: 5px;
  padding-top: 0px;
}

.youtube-logo {
  padding: 5px;
  padding-top: 0px;
}

.div-iframe-youtube {
  position: relative;
  padding-top: 56.25%;
}

.iframe-youtube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.div-video-item-youtube:hover {
  cursor: pointer;
}

.profile-pic {
  border-radius: 50%;
  width: 250px;
  height: 250px;
}

.img-profile-pic-nav {
  border: 1px solid rgb(247, 247, 247);
}

.navbar-dark .MuiCollapse-hidden {
  visibility: unset;
}

.navbar-collapse .MuiCollapse-wrapper {
  width: 100%;
}

.navbar-collapse .MuiCollapse-wrapperInner {
  display: flex;
  align-items: center;
}

.navbar-collapse {
  margin-left: 1rem;
}

.row-nav-auth {
  /* flex: 1;
  justify-content: flex-end; */
  padding: 10px 0;
  width: unset;
}

.col-mobile-profile-nav .dropdown-menu.show {
  transform: translate3d(-25px, 47px, 0px) !important;
  left: -10px !important;
  position: absolute;
  will-change: transform;
  top: 0px;
}

.nav-item-loggedout .dropdown-menu.show {
  transform: translate3d(-60px, 47px, 0px) !important;
  left: -10px !important;
}

.div-hamburger {
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.div-hamburger .navbar-toggler {
  border: 0;
}

.row-nav-auth .col-mobile-profile-nav {
  display: unset;
  width: 67px;
  height: 47px;
  padding: unset;
}

.col-mobile-profile-nav .MuitButtonBase-root {
  padding: unset;
}

.col-mobile-profile-nav .nav-item.dropdown {
  position: absolute;
}

.col-mobile-profile-nav .dropdown-toggle::after {
  display: none;
}

.mdb-nav-not-mobile-profile.navbar-nav .dropdown-toggle::after {
  display: none;
}

.mdb-nav-not-mobile-profile.navbar-nav {
  display: unset;
}

a.nav-link {
  color: hsla(0, 0%, 100%, .5);
}

a.nav-link:active {
  color: #fff;
}

a.nav-link:focus {
  color: #fff;
}

a.nav-link:hover {
  color: hsla(0, 0%, 100%, .75);
}

.NavDropdown-default .nav-link {
  color: #212529;
  padding-top: unset;
  padding-bottom: unset;
}

#root .navbar {
  padding: 0.5rem 1rem;
}

.div-hamburger .MuiButtonBase-root {
  display: unset;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgb(0 0 0 / 0);
  padding: unset;
  min-width: 47px;
  min-height: 47px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.div-hamburger .MuiButtonBase-root:hover {
  background-color: rgb(0 0 0 / 0);
}

.mdb-nav-not-mobile-profile .nav-item-profile-nav.nav-item.dropdown {
  display: none;
}

.collapse.show .navbar-nav .dropdown-menu {
  position: absolute;
}

.col-link-footer .link-footer {
  text-decoration: none;
}

.col-link-footer-right .link-footer {
  text-decoration: none;
}

.col-link-footer .link-footer:hover {
  text-decoration: underline;
}

.col-link-footer-right .link-footer:hover {
  text-decoration: underline;
}

.table-striped>tbody>tr:nth-of-type(odd)>*.wins-lb {
  color: rgb(118, 251, 0);
}

.table-striped>tbody>tr:nth-of-type(odd)>*.losses-lb {
  color: #fb6060;
}

@media screen and (min-width: 1200px) {
  .col-mobile-profile-nav {
    display: none;
  }

  .mdb-nav-not-mobile-profile .nav-item-profile-nav.nav-item.dropdown {
    display: unset;
  }

  .div-hamburger .MuiButtonBase-root {
    display: none;
  }

  .nav-item-profile-nav.nav-item.dropdown {
    position: relative;
  }

  .row-nav-auth {
    width: 0;
  }

  .row-nav-auth .col-mobile-profile-nav {
    display: none;
  }

  .mdb-nav-not-mobile-profile.navbar-nav {
    flex: 1;
    justify-content: flex-end;
    display: flex;
  }

  .navbar-dark .MuiCollapse-container {
    overflow: unset;
  }
}


.nav-item-profile-nav img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.nav-item-profile-nav .nav-link {
  padding: unset;
}

.nav-item-profile-nav .MuiButton-containedSizeLarge {
  padding: 6px 16px;
}

.nav-item-profile-nav .MuiButton-containedPrimary {
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
}

.nav-item-profile-nav .MuiButton-contained {
  box-shadow: unset;
}

.nav-item-profile-nav .MuiButton-containedPrimary:hover {
  background-color: transparent;
  color: rgba(255, 255, 255, .75);
  box-shadow: unset;
}

.container-youtube {
  color: white;
  padding: 15px;
}

.video-player-title-youtube {
  margin-top: 15px;
}

.video-thumbnail-title-youtube {
  margin-bottom: 15px;
  font-size: 13px;
}

.col-videolist-youtube {
  height: 80vh;
  overflow-y: auto;
}

.img-selected-video-youtube {
  box-shadow: 0 17px 18px 0 rgba(0, 0, 0, 0.18), 0 23px 14px 0 rgba(0, 0, 0, 0.45);
  border: 2px solid black;
  margin-left: -2px;
  margin-top: -2px;
  margin-bottom: -2px;
  border-radius: 5px;
}

.img-video-youtube {
  border-radius: 5px;
}

.img-video-youtube:hover {
  opacity: .9;
  transform: scale(1.025);
  box-shadow: 0 17px 18px 0 rgba(0, 0, 0, 0.18), 0 23px 14px 0 rgba(0, 0, 0, 0.45);
  transition: all .2s ease-in-out;
}

.footer-text {
  font-size: 11px !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #90caf9 !important;
}

.MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
  background-color: #90caf9 !important;
}

.switch-button-col-admin {
  margin-top: -5px;
  margin-bottom: -20px;
}

.navigation-rf {
  background-color: rgb(73 80 87 / .6) !important;
  border-radius: 5px;
  margin-bottom: 15px;
}

.bottom-nav-rf {
  color: gray !important;
}

.row-notice-rf .MuiCheckbox-root {
  color: #90caf9 !important;
  padding: 0px;
  padding-right: 10px;
}

.row-notice-rf .MuiCheckbox-colorPrimary.Mui-checked {
  color: #90caf9 !important;
}

.p-notice-rf {
  color: white;
  padding: 10px;
  text-align: justify;
}

.table-edit-rf .MuiTableCell-root {
  font-size: 1rem;
}

.table-edit-rf.MuiPaper-root {
  background-color: #424242;
}

.table-edit-rf .MuiTableCell-head {
  color: white
}

.table-edit-rf .MuiTableCell-root {
  border-bottom: 1px solid rgba(81, 81, 81, 1);
}

.table-edit-rf .MuiTableCell-body {
  color: white
}

.table-edit-rf .MuiIconButton-root {
  color: white;
}

.table-edit-rf .MuiTableCell-footer {
  color: white;
  background-color: rgba(81, 81, 81, 1);
}

.div-edit-rf {
  background-color: rgb(51, 55, 60);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.icon-checked-rf {
  font-size: 1.5rem;
}

.list-edit-rf.MuiList-root {
  background-color: #424242;
  border-radius: 5px;
}

.list-edit-rf .MuiTypography-body1 {
  color: white;
}

.list-edit-rf .MuiTypography-colorTextSecondary {
  color: rgba(255, 255, 255, 0.24);
}

.list-edit-rf .MuiIconButton-label {
  color: white;
}

.list-edit-rf .MuiListItem-container {
  border-bottom: 1px solid rgba(81, 81, 81, 1);
}

.list-edit-rf .MuiAvatar-colorDefault {
  color: black;
}

.col-textfield-rf .WithStyles\(ForwardRef\(TextField\)\)-root-5 .MuiInputBase-input {
  font-size: 14px;
}

.col-textfield-rf .MuiOutlinedInput-input {
  padding: 18.5px 14px;
}

.col-textfield-right-rf {
  margin-left: -15px;
}

.col-textfield-left-rf {
  display: flex;
  justify-content: flex-end;
}

.row-group-name-rf {
  padding-bottom: 10px;
}

.h5-group-name-rf {
  color: white;
  margin: 0;
}

.row-transaction-rf {
  justify-content: flex-end;
}

.h5-transaction-rf {
  font-size: 1rem;
  position: absolute;
  display: flex;
  margin: 0;
  color: white;
  margin-top: 10px;
  margin-right: 15px;
}

.div-back-button-rf {
  margin-bottom: 10px;
  position: absolute;
}

.div-back-button-2-rf {
  padding-left: 10px;
  padding-top: 10px;
}

.div-back-button-2-rf .MuiButton-containedPrimary {
  background-color: #6f6f6f
}

.div-back-button-2-rf .MuiButton-containedPrimary:hover {
  background-color: #a1a1a1
}

.div-back-button-rf .MuiButton-containedPrimary {
  background-color: #6f6f6f
}

.div-back-button-rf .MuiButton-containedPrimary:hover {
  background-color: #a1a1a1
}

.table-cell-button-rf .MuiButton-containedPrimary {
  background-color: #008e2b
}

.table-cell-button-rf .MuiButton-containedPrimary:hover {
  background-color: #005c1c
}

.collapse-table-row-rf .MuiTableCell-root {
  background-color: rgb(119, 119, 119);
}

.div-add-rental-rf {
  padding: 20px;
  margin-bottom: 15px;
}

.h5-add-rental-rf {
  color: white;
  text-align: center;
}

.button-next-create-rf {
  width: 100%;
}

.div-modal-settings-rf {
  width: 100%;
  padding: 24px;
}

.div-modal-settings-rf .MuiPaper-root {
  background-color: #4a4a4a;
  color: white;
}

.div-modal-settings-rf .MuiTypography-root {
  color: white;
}

.col-applyall-rf {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.col-applyall-rf .MuiButtonBase-root {
  background-color: #90caf9;
}

.col-applyall-rf .MuiButtonBase-root:hover {
  background-color: #4a9ee2;
}

.col-applyall-rf .MuiButton-label {
  color: white;
}

.col-applyall-rf .MuiButton-root.Mui-disabled {
  background-color: rgb(4, 4, 4, .2);
}

.col-applyall-rf .MuiButton-root.Mui-disabled .MuiButton-label {
  color: darkgray;
}

.accordion-details-rentals-rf {
  height: 400px;
  overflow: scroll;
}

.accordion-actions-rentals-rf .MuiButton-root {
  background-color: #90caf9;
}

.accordion-actions-rentals-rf .MuiButton-root:hover {
  background-color: #4a9ee2;
}

.accordion-actions-rentals-rf .MuiButton-label {
  color: white;
}

.MuiAlert-standardError {
  background-color: #f44336 !important;
  color: white !important;
}

.MuiAlert-standardError .MuiAlert-icon {
  color: white !important;
}

.MuiAlert-standardSuccess {
  background-color: #4caf50 !important;
  color: white !important;
}

.MuiAlert-standardSuccess .MuiAlert-icon {
  color: white !important;
}

.col-save-button-ro {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.col-save-button-ro .MuiButton-root {
  background-color: #90caf9;
}

.col-save-button-ro .MuiButton-root:hover {
  background-color: #4a9ee2;
}

.col-save-button-ro .MuiButton-label {
  color: white;
}

.button-submit-ro {
  margin-right: 15px !important;
}

.header1-ro {
  color: white;
  margin: 0;
}

.header2-ro {
  color: white;
  margin: 0;
  margin-left: -15px;
}

.header3-ro {
  color: white;
  margin: 0;
  margin-left: -30px;
}

.col-rentals-ro .MuiInputBase-input.Mui-disabled {
  color: gray;
}

.col-header-ro {
  width: 20%;
}

.div-header-ro {
  display: flex;
  align-items: center;
  margin: 5px;
  width: 600px !important;
}

.row-teams {
  max-width: 654px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.row-next-button-rf {
  margin-top: 15px;
}

.row-next-button-rf .MuiButtonBase-root {
  background-color: #90caf9;
}

.row-next-button-rf .MuiButtonBase-root:hover {
  background-color: #4a9ee2;
}

.button-hide-nav-rf {
  width: 100%;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.row-hidenav-rf {
  margin-bottom: 15px;
  margin-top: -20px !important;
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.row-hidenav-rf .MuiButtonBase-root {
  background-color: #90caf9;
}

.row-hidenav-rf .MuiButtonBase-root:hover {
  background-color: #4a9ee2;
}

.col-rental-rows-rf {
  display: grid;
}

.col-notice-rf {
  color: white;
  background-color: #424242;
  padding: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.col-total-price-rf {
  color: white;
  background-color: #424242;
  padding: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.p-total-price-rf {
  border-top: 1px solid white;
  margin: 0 15px;
  color: white;
  flex: 1;
  text-align: center;
}

.col-md-6 .makeStyles-root-1 {
  min-width: 100%;
}

.margin15-bottom {
  margin-bottom: 15px;
}

.col-rentals-esf .MuiChip-label {
  font-size: 15px;
}

.col-rentals-esf .MuiChip-root {
  height: 36px;
  color: white;
}

.col-rentals-esf .MuiChip-root .MuiChip-avatar {
  font-size: 1rem;
}

.th-esf .MuiIconButton-root {
  margin-top: -3px
}

.table-collapse-esf .MuiTableCell-root {
  width: 33.33%;
}

.tc-name-esf .MuiButton-label {
  text-shadow: none;
  font-weight: 400;
}

.tc-name-esf .MuiButton-root {
  font-size: inherit;
  color: white;
  letter-spacing: inherit;
  text-transform: inherit;
  background-color: rgb(244, 143, 177, .2)
}

.tc-name-esf .MuiButton-root:hover {
  background-color: rgba(172, 0, 57, 0.8)
}

.tc-name-esf .MuiButton-root.Mui-disabled {
  color: white;
  background-color: inherit;
}

.button-remove-esf {
  margin-left: 15px !important;
}

.div-checkbox-esf .MuiCheckbox-colorPrimary.Mui-checked {
  color: #90caf9;
}

.counter {
  background-color: #f5f5f5;
  padding: 35px 0;
  border-radius: 5px;
}

.counter i,
.counter .count-title {
  margin-bottom: 15px;
  color: black;
}


.counter p {
  font-style: italic;
  margin-bottom: 0;
  color: black;
}

.stat-box {
  margin-bottom: 30px;
}

.paper-rental-row-rf.MuiPaper-root {
  float: right;
  width: 400px;
  margin: 5px;
  display: flex;
  padding: 2px 4px;
  background: #424242;
  align-items: center;
}

.paper-rental-row-rf .MuiInputBase-root {
  color: white;
  width: 20%;
  margin-left: 8px;
}

.paper-rental-row-rf h5 {
  flex: 1;
  color: white;
  margin: 0;
  font-size: 1rem;
  margin-left: 15px;
  margin-right: 15px;
}

.paper-rental-row-rf .MuiDivider-vertical {
  height: 28px;
  margin: 4px;
  background: rgba(255, 255, 255, 0.12);
}

.h5-rentalform {
  width: unset !important;
}

/*For remove button on esf*/
.MuiTableCell-root .MuiButton-containedSecondary:hover {
  background-color: #7d012d;
}

.MuiBottomNavigationAction-root.Mui-selected {
  color: white !important;
}

.h2-modal-rf {
  color: white;
  text-align: center;
}

.div-selected-rf {
  background-color: rgb(73 80 87 / .6);
  border-radius: 5px;
}

.row-rf {
  padding-bottom: 10px;
}

.form-rf {
  color: white;
  padding: 10px;
  padding-bottom: 0;
}

.col-margin15-right {
  margin-right: 15px;
}

.status-text-rf {
  align-items: center;
  display: flex;
  margin-right: 15px;
  margin-bottom: 0rem;
  color: red;
}

.row-error-rf {
  color: red;
  justify-content: center;
  margin-top: 15px;
  background-color: #424242;
  padding: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  text-align: center;
}

.table-cell-total-participants-rf {
  color: white;
  padding: 5px;
  font-size: 1rem;
}

.participant-p {
  background-color: rgb(73 80 87 / .6);
  border-radius: 5px;
  color: white;
  padding: 5px;
  margin-bottom: 0;
}

.div-checked-in-rf {
  text-decoration: line-through;
  color: red;
  margin: 5px;
  display: initial;
}

.div-checked-in-rf .MuiButton-text {
  padding: 3px 5px;
}

.div-checked-in-rf .MuiButton-label {
  color: white;
  font-size: 1.4rem;
}

.div-checked-in-rf .MuiTouchRipple-root {
  border: 2px solid red;
  border-radius: 5px;
  box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 3px rgba(0, 0, 0, 0.14), 0px 1px 3px -1px rgba(0, 0, 0, 0.12)
}

.div-checked-out-rf {
  margin: 5px;
  display: initial;
}

.div-checked-out-rf .MuiButton-text {
  padding: 3px 5px;
}

.div-checked-out-rf .MuiButton-label {
  font-size: 1.4rem;
  color: white;
}

.div-checked-out-rf .MuiTouchRipple-root {
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 3px rgba(0, 0, 0, 0.14), 0px 1px 3px -1px rgba(0, 0, 0, 0.12)
}

.div-checked-in-rf .MuiButton-root:hover {
  background-color: rgb(255, 87, 87, .3)
}

.div-checked-out-rf .MuiButton-root:hover {
  background-color: rgba(255, 255, 255, 0.3)
}

.tc-notice-rf {
  color: rgb(110 255 129) !important;
}

.add-rental-button-rf {
  background-color: #90caf9 !important;
  color: rgba(0, 0, 0, 0.87);
}

.add-rental-button-rf .MuiButton-label {
  text-shadow: none !important;
}

.align-items-center-col {
  align-items: center;
  display: flex;
}

.rental-label-rf {
  padding-left: 0 !important;
}

.status-text-rf2 {
  color: white;
}

.row-dropdown-months-lb {
  justify-content: center;
}

.dropdown-lb .btn-primary {
  background-color: rgb(73 80 87 / .6);
  border: none;
}

.dropdown-lb .btn-primary:hover {
  background-color: rgb(22 22 22 / .8) !important;
}

.dropdown-lb .btn-primary:active {
  border: none;
  background-color: rgb(22 22 22 / .8) !important;
}

.dropdown-lb .btn-primary:focus {
  border: none;
  background-color: rgb(22 22 22 / .8);
  box-shadow: none;
  border-color: none;
}

.h5-dashboard {
  color: white;
  text-align: center;
}

.p-error-text-dashboard {
  margin-bottom: 5px;
  color: red;
}

.container-pin-code-dashboard {
  background-color: rgb(73 80 87 / .6);
  padding: 25px;
  padding-top: 0px;
  border-radius: 5px;
}

.div-pin-code-dashboard {
  padding: 15px;
}

.img-logo-dashboard {
  height: auto;
  width: 200px !important;
  margin: 0;
}

.row-img-logo-dashboard {
  align-items: center;
}

.hours-logo-home {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

.col-button-renew-uo {
  display: flex;
  align-items: center;
  margin-bottom: -15px;
}

.waiver-row-renew {
  background-color: rgb(108, 117, 125, 0.75) !important;
  border-radius: 5px;
}

.validate-button-wl {
  font-size: .8rem !important;
  margin-left: 15px;
  color: black !important;
  padding-top: 6.5px !important;
  padding-bottom: 6.5px !important;
}

.check-button-wl {
  font-size: .7rem !important;
  margin-left: 15px;
  padding: 8px 10px !important;
  color: black !important;
}

.div-main-birthday {
  background-color: rgb(108, 117, 125, 0.75);
  border-radius: 5px;
}

.div-main-birthday .rbc-month-view {
  height: 70vh;
}

.div-main-birthday .rbc-toolbar-label {
  color: white;
  font-size: 24px;
}

.div-main-birthday .rbc-toolbar {
  margin: 10px;
}

.div-main-birthday .rbc-off-range-bg {
  background-color: rgb(4, 4, 4, .8);
}

.div-main-birthday .rbc-date-cell {
  color: white;
}

.div-main-birthday .rbc-date-cell.rbc-now {
  color: black;
}

.div-main-birthday .rbc-day-bg+.rbc-day-bg+.rbc-today {
  background-color: rgb(138, 138, 138);
}

.div-main-birthday .rbc-toolbar button {
  color: white;
}

.div-main-birthday .rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: #373a3c;
}

.div-main-birthday .rbc-header.rbc-today {
  color: black;
  background-color: rgb(138, 138, 138);
}

.div-main-birthday .rbc-header {
  color: white;
}

.div-main-birthday .rbc-label {
  color: white;
}

.div-main-birthday .rbc-today .rbc-timeslot-group {
  background-color: rgb(138, 138, 138);
}

.div-main-birthday .rbc-agenda-event-cell,
.div-main-birthday .rbc-agenda-time-cell,
.div-main-birthday .rbc-agenda-date-cell {
  color: rgba(255, 255, 255, 0.6);
}

.dialog-create-birthday .MuiDialogActions-root {
  border-top: 2px solid black;
  background-color: rgb(77, 77, 77, .8);
}

.dialog-edit-birthday .MuiDialogActions-root {
  border-top: 2px solid black;
  background-color: rgb(77, 77, 77, .8);
}

.actions-create-birthday .MuiButton-textPrimary {
  color: white;
}

.actions-create-birthday .MuiButton-textPrimary:hover {
  color: white;
  background-color: rgb(4, 4, 4, .2);
}

.actions-create-birthday .MuiButton-label {
  text-shadow: unset;
}

.content-create-birthday .MuiFormLabel-root.Mui-focused {
  color: #4d4d4d;
  ;
}

.content-create-birthday .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4d4d4d;
}

.content-create-birthday .MuiCheckbox-colorSecondary.Mui-checked {
  color: #4d4d4d;
  ;
}

.content-create-birthday .MuiIconButton-colorSecondary:hover {
  background-color: rgb(77, 77, 77, .1);
}

.row-deposit-birthday {
  padding-left: 10px;
  margin-bottom: 10px;
}

.row-notes-birthday {
  margin-top: 10px;
  margin-bottom: 10px;
}

.col-rank-title-teams {
  font-weight: 300;
  color: white;
}

.col-team-title-teams {
  font-weight: 300;
  color: white;
}

.col-points-title-teams {
  font-weight: 300;
  color: white;
  justify-content: flex-end;
  display: flex;
}

.row-pagination-bot-teams {
  justify-content: flex-end;
  margin-top: 30px;
}

.div-parent-teams {
  width: 100%;
  overflow: hidden;
}

.div-parent-teams .MuiTableCell-root {
  border: none;
}

.col-team-name-teams {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5px;
}

.td-team-imgname-teams {
  font-weight: 300 !important;
  color: #595959 !important;
  border: 1px solid #595959 !important;
  padding: 5px !important;
}

.td-team-imgname-teams a {
  color: white;
  font-size: 12px;
}

.col-team-picture-teams {
  display: flex;
  justify-content: center;
}

.td-team-imgname-teams a:hover {
  color: white;
}

.position-relative {
  position: relative;
}

.spinner-table-teams {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  color: white;
}

.row-settings {
  justify-content: center;
  margin-top: 15px;
}

p.p-picture-label-settings {
  margin: none;
  font-size: 16px;
  font-weight: 300;
  color: white;
  width: unset;
}

.container-settings .MuiTab-textColorPrimary.Mui-selected {
  color: white;
}

.container-settings .MuiTabs-indicator {
  background-color: transparent;
}

.container-settings .MuiPaper-root {
  background-color: rgba(255, 255, 255, 0.1);
  ;
}

.container-settings .MuiTab-textColorPrimary {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
}

.crop-container-settings {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls-settings {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.div-upload-settings input {
  display: none;
}

.div-upload-settings .MuiButton-containedPrimary {
  background-color: #494949;
}

.div-upload-settings .MuiButton-containedPrimary:hover {
  background-color: #6d6d6d;
}

.div-crop-image-settings .MuiButton-containedPrimary {
  background-color: #494949;
  margin-right: -15px;
}

.div-crop-image-settings .MuiButton-containedPrimary:hover {
  background-color: #6d6d6d;
}

.col-update-button-settings {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.col-update-button-settings .MuiButton-containedPrimary {
  background-color: #494949;
}

.col-update-button-settings .MuiButton-containedPrimary:hover {
  background-color: #6d6d6d;
}

.row-img-settings {
  position: relative;
  color: white;
  font-weight: 500;
}

.div-img-settings:hover .profile-img-settings {
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
  opacity: .4;
  cursor: pointer;
}

.div-img-settings {
  border-radius: 50%;
  position: relative;
}

.div-img-settings:hover .div-img-text-settings {
  display: unset;
}

.div-img-text-settings:hover {
  cursor: pointer;
}

.div-img-text-settings {
  color: white;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  white-space: pre-line;
}

.div-img-button-settings {
  position: absolute;
  top: 5%;
  right: 10%;
}

.div-img-button-settings {
  background-color: #494949;
  border-radius: 50%;
  min-width: 50px;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.div-img-button-settings:hover {
  cursor: pointer;
}

.div-account-inputs-settings {
  padding-top: 15px;
  min-height: 100%;
  flex-direction: column;
  display: flex;
}

.row-parent-settings {
  margin: 15px 0 !important;
  background-color: rgba(255, 255, 255, 0.1);
  padding-bottom: 15px;
}

.row-password-settings {
  padding-top: 15px;
  min-height: 100%;
}

.verify-form {
  color: white;
  margin-right: 15px;
  margin-left: 15px;
}

.form-control-cur-email-settings {
  background-color: #adadad !important;
  color: black !important;
}

.rbc-calendar {
  display: unset !important;
}

.div-crop-image-settings {
  position: relative;
  width: 300px;
  height: 400px;
  border: 1px solid #3a3a3a;
}

@media (min-width: 576px) {
  .div-crop-image-settings {
    width: 300px;
    height: 300px;
  }
}

@media (min-width: 768px) {
  .div-crop-image-settings {
    width: 450px;
    height: 450px;
  }
}

@media (min-width: 992px) {
  .div-crop-image-settings {
    width: 550px;
    height: 550px;
  }
}

@media (min-width: 1200px) {
  .div-crop-image-settings {
    width: 700px;
    height: 700px;
  }
}

.season-button-row .MuiButton-root {
  width: 100%;
  border-radius: unset;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 700;
  border: unset;
}

.season-button-row .MuiButton-root:hover {
  border: unset;
}

.selected-date-col-schedule {
  display: flex;
  justify-content: center;
}

.selected-date-events-col {
  display: flex;
  justify-content: center;
}

label.upload-button-label {
  width: unset;
}

.season-button-row .MuiButton-outlined {
  background-color: rgb(247 247 247 / 25%);
}

.season-button-row .MuiButtonBase-root {
  margin-bottom: 1rem;
}

.season-button-row .MuiButton-label {
  text-shadow: none;
}

.season-button-row .MuiButton-root:hover {
  background-color: rgb(165, 165, 165);
}

.season-button-row .season-button-active {
  background-color: rgb(165, 165, 165);
}

.season-button-row .col-md-2 {
  padding-left: 0;
  padding-right: 0;
}

.div-crop-image-settings .MuiSlider-root {
  color: #aeb1c1;
  margin-right: 15px;
}

.div-cancel-button-settings {
  position: absolute;
  z-index: 2;
}

.a-youtube-link {
  color: white !important;
}

.a-instagram-link {
  color: white !important;
}

.tr-personal-rank-leaderboard {
  border-bottom: 10px solid black;
}

.h5-title-summary {
  text-align: center;
  color: white;
  border-bottom: 1px solid #777777;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.p-cc-summary {
  margin: 0;
}

.p-error-summary {
  margin: 0;
  color: red;
}

.div-cc-summary .MuiFormLabel-root.Mui-focused {
  color: #90caf9 !important;
}

.div-cc-summary .MuiInputBase-input {
  color: white;
}

.div-cc-summary .MuiFormLabel-root {
  color: white;
}

.div-cc-summary .MuiInput-underline:after {
  border-bottom-color: #90caf9;
  color: white;
}

.div-cc-summary .label.Mui-focused {
  color: '#90caf9',
}

.div-cc-summary .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-bottom-color: #90caf9;
}

.div-cc-summary .MuiOutlinedInput-notchedOutline {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  border-bottom-color: white;
  margin: 0 15px;
}

.div-cc-summary .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #90caf9;
}

.div-cc-summary .MuiOutlinedInput-input {
  padding: 15px 15px;
}

.div-cc-summary {
  color: white;
  border-bottom: 1px solid #777777;
  margin-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
}

.div-email-rf .MuiFormLabel-root.Mui-focused {
  color: #90caf9 !important;
}

.div-email-rf .MuiInputBase-input {
  color: white;
}

.div-email-rf .MuiFormLabel-root {
  color: white;
}

.div-email-rf .MuiInput-underline:after {
  border-bottom-color: #90caf9;
  color: white;
}

.div-email-rf .label.Mui-focused {
  color: '#90caf9',
}

.div-email-rf .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-bottom-color: #90caf9;
}

.div-email-rf .MuiOutlinedInput-notchedOutline {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  border-bottom-color: white;
  margin: 0 15px;
}

.div-email-rf .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #90caf9;
}

.div-email-rf .MuiOutlinedInput-input {
  padding: 15px 15px;
}

.div-email-rf {
  color: white;
  margin-bottom: 15px;
  display: flex;
}

.div-email-rf .MuiInputBase-root {
  width: 300px;
}

.p-groupname-summary {
  margin: 0;
  margin-left: 16px;
}

.div-groupname-summary {
  color: white;
  border-bottom: 1px solid #777777;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.div-summary {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.div-summary .MuiButton-containedPrimary {
  background-color: #008e2b
}

.div-summary .MuiButton-containedPrimary:hover {
  background-color: #005c1c
}

.img-small-rank {
  width: 32px;
}

img.img-normal-rank {
  width: 70px;
}

img.img-large-rank {
  flex-shrink: initial;
  width: initial;
  max-width: initial;
  padding-right: initial;
  padding-left: initial;
  margin-top: initial;
  width: 85px;
}

.img-xlarge-rank {
  width: 245px;
}

.date-tc-profile {
  color: white !important;
  font-weight: bold;
}

.email-template-div {
  position: relative;
  height: 1000px;
}

.email-template-div .MuiDrawer-paper {
  position: absolute;
  width: 57px;
  overflow-x: hidden;
}

.email-template-base-div {
  padding-left: 57px;
}

.email-templates-title-row {
  padding-left: 72px;
}

.email-templates-title-h {
  color: black;
  flex: 1;
  background-color: rgb(255, 255, 255);
  padding: 10px;
}

.email-template-card-actions .MuiButton-textPrimary {
  color: unset;
}

.email-template-card-actions .MuiButton-label {
  text-shadow: none;
}

.email-template-card-actions .MuiButton-textPrimary {
  background-color: black;
  color: white;
}

.email-template-card-actions .MuiButton-textPrimary:hover {
  background-color: rgb(51, 51, 51, .8);
}

.div-parent-admin {
  position: relative;
}

.background-static-admin {
  min-height: 91vh !important;
}

/* .div-parent-admin .MuiDrawer-paper {
  position: absolute;
} */

.div-parent-admin .MuiAppBar-root {
  /* position: absolute; */
}

.pricing-update-title-h2 {
  text-align: center;
  background-color: rgb(168, 0, 0);
  padding: 16px 32px;
  font-size: 1rem;
  color: white;
}

.pricing-update-inner-div {
  padding: 16px 32px 24px;
  color: white;
}

.justify-content-flex-end-div {
  display: flex;
  justify-content: flex-end;
}

.form-control-disabled-signup .form-control:disabled {
  background-color: rgb(157, 157, 157);
}

.second-page-signup-col {
  background-color: rgb(73 80 87 / .6);
  border-radius: 5px;
  padding: 15px;
}

.standard-navigation-buttons-row-pricing {
  padding-bottom: 15px;
}

.standard-card-header-pricing {
  background-color: #082544 !important;
  color: white;
}

.new-player-card-header {
  background-color: #082544 !important;
  color: white;
}

.non-member-card-header {
  background-color: #0b3888 !important;
  color: white;
}

.weekday-card-header-pricing {
  background-color: #0b3888 !important;
  color: white;
}

.member-card-header {
  background-color: #a44347 !important;
  color: white;
}

.first-row-card-pricing {
  justify-content: center;
}

.second-row-card-pricing {
  margin-top: -18px;
  justify-content: center;
}

.justify-content-flex-end-div .MuiButton-root {
  color: white;
}

.div-parent-admin .MuiListItem-gutters {
  padding-left: 22px;
  padding-right: 22px;
}

.close-button-admin .MuiSvgIcon-root {
  fill: rgb(255, 255, 255);
  z-index: 1200;
}

.last-tab-list-admin-drawer .MuiButtonBase-root:hover {
  color: unset;
}

.paper-shadow {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.more-info-button-user-table .MuiButton-label {
  color: white;
}

.user-table-footer .MuiTablePagination-caption {
  color: rgba(255, 255, 255, .9);
}

.user-table-footer .MuiSelect-select.MuiSelect-select {
  color: rgba(255, 255, 255, .9);
}

.user-table-footer .MuiSelect-select.MuiSelect-select option {
  color: black;
}

.user-table-footer .MuiIconButton-root.Mui-disabled {
  color: rgba(134, 134, 134, 0.26)
}

.user-table-footer .MuiSelect-icon {
  color: rgba(255, 255, 255, .9);
}

.user-table-footer .MuiIconButton-root {
  color: rgba(255, 255, 255, .9);
}

.more-info-button-user-table:hover {
  background-color: rgb(41 48 60);
}

.div-parent-admin .MuiPaper-root {
  background-color: rgba(22, 22, 22, 0.9);
  color: #fff;
  border-right: none;
}

.div-parent-admin .MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: linear-gradient(90deg, rgba(35, 38, 38, 0.7175245098039216) 12%, rgba(0, 0, 0, 1) 100%);
}

.div-parent-admin .MuiListItem-root.Mui-selected {
  background-color: rgb(0 0 0 / 90%);
}

.list-admin-drawer .MuiListItem-root.Mui-selected:hover {
  background-color: rgb(0 0 0 / 90%);
}

.list-admin-drawer .MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.div-parent-admin .MuiTableContainer-root {
  width: 97%;
}

.img-logo-admin {
  max-height: 64px;
}

.list-admin-drawer .MuiListItemIcon-root {
  color: rgb(255 255 255 / 54%);
}

.last-tab-list-admin-drawer {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.add-participant-form-group-rf .MuiCheckbox-colorPrimary.Mui-checked {
  color: black;
}

.add-participant-form-group-rf {
  margin-bottom: 0 !important;
}

.row-subscribe-email-rp .form-group {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}

.row-subscribe-email-rp .MuiCheckbox-colorPrimary.Mui-checked {
  color: black;
}

.div-photo-cm {
  margin-bottom: 1.75rem;
}

.row-fullname-ip {
  justify-content: center;
  color: white;
}

.accordion-details-rentals-rf {
  overflow-x: hidden;
}

.list-item-unfinished-rental-ef {
  background-color: rgb(160, 63, 63, .6);
}

.row-cur-rank-rp {
  background-color: rgba(17, 17, 17, 0.7);
  overflow: hidden;
  /* margin-top: 1rem; */
  /* clip-path: polygon(
    0 10%,
    5% 0,
    100% 0,
    100% 0%,
    100% 100%,
    106% 100%,
    10% 100%,
    0% 100%,
    0% 0%
  ) */
}

.div-stats-box-profile {
  background-color: rgba(17, 17, 17, 0.7);
  overflow: hidden;
  margin-top: 1rem;
  clip-path: polygon(0 10%,
      4% 0,
      100% 0,
      100% 0%,
      100% 100%,
      106% 100%,
      10% 100%,
      0% 100%,
      0% 0%);
  padding: 20px;
}

.p-stats-box-profile {
  color: white;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 0 !important;
  text-align: center;
}

.p-title-stats-box-profile {
  color: gray;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 0 !important;
  text-align: center;
}

.col-rank-box-profile {
  align-items: center;
  justify-content: center;
  display: flex;
}

.row-next-rank-rp {
  background-color: rgba(68, 67, 67, 0.7);
  overflow: hidden;
  /* margin-top: 1rem; */
  /* clip-path: polygon(
    0 7%,
    5% 0,
    100% 0,
    100% 0%,
    100% 100%,
    106% 100%,
    10% 100%,
    0% 100%,
    0% 0%
  ) */
}

.row-points-rp {
  background-color: rgb(37, 41, 45, .7);
  overflow: hidden;
  margin-top: 1rem;
  clip-path: polygon(0 30%,
      3% 0,
      100% 0,
      100% 0%,
      100% 100%,
      106% 100%,
      10% 100%,
      0% 100%,
      0% 0%)
}

.col-match-history-rp {
  background-color: rgb(37, 41, 45, .7);
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem;
  clip-path: polygon(0 5%,
      2% 0,
      100% 0,
      100% 0%,
      100% 100%,
      106% 100%,
      10% 100%,
      0% 100%,
      0% 0%);
}

.form-control-expired-uo {
  background-color: rgb(160, 63, 63, .6) !important;
  color: white !important;
}

.p-rank-title-profile {
  color: gray;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
}

.p-rank-title-profile:before,
.p-rank-title-profile:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid rgb(128 128 128);
  margin: auto;
}

.p-rank-title-profile:before {
  margin-right: 10px;
}

.p-rank-title-profile:after {
  margin-left: 10px;
}

.col-center-middle {
  justify-content: center;
  display: flex;
  align-items: center;
}

div .p-rank-title-rp {
  color: gray;
  text-align: center;
  margin-bottom: 0;
  max-width: 100px;
  line-height: 1.2;
}

.p-next-rank-title-rp {
  color: gray;
  text-align: center;
  margin-bottom: 0;
}

.row-rank-progress-profile {
  margin-top: 1rem;
}

.row-rank-progress-profile .MuiCircularProgress-circleDeterminate {
  color: rgb(39, 39, 39);
}

.cp-static-profile {
  position: absolute;
  translate: 5%;
}

.cp2-static-profile {
  translate: 5%;
}

.cp-static-profile .MuiCircularProgress-circleDeterminate {
  color: rgb(165, 165, 165, .8);
  z-index: -1;
}

.p-match-history-title-rp {
  color: white;
  font-weight: 600;
  margin-bottom: 0;
  padding-left: 15px;
  padding: 10px;
}

.row-table-match-history-rp .MuiPaper-root {
  background-color: transparent;
  max-height: 400px;
}

.row-table-match-history-rp .MuiTableCell-stickyHeader {
  background-color: rgb(25, 27, 27) !important;
}

.row-table-match-history-rp .MuiTableCell-root {
  text-align: center;
  color: gray;
  background-color: rgb(43, 43, 43, 0.70);
}

.col-match-history-rp .MuiTableHead-root {
  background-color: rgb(25, 27, 27) !important;
}

.row-table-match-history-rp .MuiTableCell-head {
  color: white !important;
}

.p-page-title-rp {
  margin-top: 1rem;
  font-size: 24px;
  font-weight: 600;
  color: rgb(144, 202, 249, .5);
  text-align: center;
}

.countdown-div-home {
  position: relative;
  margin-bottom: 3px;
}

.countdown-row-home {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.countdown-titles-row-home {
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.countdown-col-number-home {
  color: white;
  font-size: 20vw;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-family: "Teko";
  text-shadow: 8px 8px 16px #000000;
  /* -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: black; */
}

.countdown-col-title-home {
  color: white;
  font-size: 3vw;
  display: flex;
  justify-content: center;
  font-family: "Teko";
}

.countdown-img-home {
  position: relative;
  top: 0;
  left: 0;
}

.soldiers-img-home {
  position: absolute;
  top: 00;
  left: 0;
}

.countdown-row-number-home {
  width: 80vw;
  justify-content: center;
}

.countdown-row-title-home {
  width: 80vw;
  justify-content: center;
  padding-bottom: 25px;
}

.disclaimer-col-home {
  color: white;
  display: flex;
  justify-content: center;
}

.disclaimer-div-home {
  margin-top: -35px;
}

.padding-0 {
  padding: 0 !important;
}

.col-title-rp {
  flex: 0 0 35% !important;
  max-width: 35% !important;
}

.row-progress-bar-rp {
  padding: 5px;
  justify-content: center;
}

.row-progress-bar-rp .progress {
  background-color: gray;
  width: 90%;
}

.row-progress-bar-rp .progress-bar {
  background-color: rgb(144, 202, 249, .5);
}

.p-rank-progress-rp {
  color: gray;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 12px;
}

.p-ranks-rp {
  color: gray;
  text-align: center;
  font-size: 12px;
  margin-bottom: 0;
}

.row-rank-name-rp {
  height: 30%;
}

.row-parent-rp {
  min-height: 50vh;
  flex-wrap: wrap;
  align-items: center;
}

.col-rank-icon-rp {
  background-color: rgba(21, 23, 26, 0.7);
}

.col-your-rank-rp {
  background-color: rgba(21, 23, 26, 0.7);
  border: 1px solid white;
  border-radius: 5px;
}

.col-logo-points-rp {
  padding: 0;
  margin: 0;
  height: 70%;
}

.col-navigation-left-rp {
  display: flex;
  justify-content: center;
}

.col-navigation-right-rp {
  display: flex;
  justify-content: center;
}

.col-navigation-rp {
  align-items: center;
  display: flex;
}

.col-navigation-rp .MuiSvgIcon-root {
  font-size: 2.5rem;
  color: white;
}

.col-navigation-rp .MuiIconButton-root.Mui-disabled .MuiSvgIcon-root {
  font-size: 2.5rem;
  color: gray;
}

.div-profile-main-p {
  padding-top: 10px;
}

.row-profile-main-p {
  /* border: 1px solid black;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  padding-left: 15px;
  padding-right: 15px;
}

.row-username-p {
  /* background-color: rgba(0, 0, 0, 0.8); */
  color: white;
  padding: 2px 5px;
  font-weight: 500;
  font-size: 24px;
  /* text-shadow: 0 0 15px rgba(255,255,255,.5), 0 0 10px rgba(255,255,255,.5); */
}

.p-rank-category-title {
  color: white;
  margin-bottom: 0 !important;
  font-size: 22px;
  font-weight: 300;
}

.row-rank-category-title {
  justify-content: center;
  background-color: #585858a6;
  clip-path: polygon(0 35%,
      2% 0,
      100% 0,
      100% 0%,
      100% 100%,
      106% 100%,
      10% 100%,
      0% 100%,
      0% 0%);
}

.row-officer-rank-category-title {
  justify-content: center;
  background-color: #585858a6;
}

.row-box-title-p {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 2px 5px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgb(255 255 255 / .1);
}

.row-details-p {
  color: white;
  padding: 2px 5px;
  /* border: 1px solid rgb(255 255 255 / .1); */
  font-weight: 300;
}

.p-details-p {
  font-size: 20px;
  margin: 0;
  color: white;
  text-decoration: none;
}

.p-details-p:hover {
  color: white;
}

a:hover.p-details-p {
  text-decoration: underline;
}

a.admin-cards-link {
  color: transparent;
}

.admin-cards .MuiTableCell-body {
  color: white;
}

div .span-details-p {
  width: unset;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 12px;
}

.img-profile-p {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

div .p-nav-profile {
  width: unset;
  font-weight: 300;
  padding: 0 15px;
  font-size: 22px;
  color: rgba(255, 255, 255, .5);
  margin: 0 !important
}

.p-nav-profile:hover {
  color: white;
  cursor: pointer;
}

div .p-nav-profile-active {
  color: white;
  font-weight: 300;
  padding: 0 15px;
  font-size: 22px;
  margin: 0 !important;
  width: unset;
}

.p-nav-profile-active:hover {
  cursor: pointer;
}

.col-profile-pic-p {
  padding-left: 0 !important;
  display: flex;
  justify-content: flex-end;
}

.col-mobile-profile-pic-p {
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 150px !important;
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.col-stats-p {
  /* background-color: rgb(107, 107, 107, .5); */
  background-color: rgb(37, 41, 45, .7);
}

.col-left-box-p {
  flex: 0 0 63.666667%;
  max-width: 63.666667% !important;
  margin-right: 25px;
}

.col-justify-content-flex-end {
  justify-content: flex-end;
  display: flex;
}

.col-justify-content-flex-start {
  justify-content: flex-start;
  display: flex;
}

.row-rank-name-rp {
  min-height: 40%;
}

.switch-off.btn {
  padding-left: 12px !important;
}

.button-more-info-profile .MuiButton-label {
  text-shadow: none;
  color: rgb(172, 171, 171);
}

.row-parent-profile {
  align-items: flex-end;
}

.row-title-rp {
  justify-content: center;
}

.row-bottom {
  padding-bottom: 15px;
}

.p-notice-text-home {
  color: rgba(148, 148, 148, 0.9);
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 0 !important;
  padding-top: 20px;
  text-align: center;
}

.link-img-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.link-img-nav img {
  min-width: 56vw;
  position: absolute;
}

.col-image-home {
  align-items: center;
  justify-content: center;
  display: flex;
}

/* For pincode css */
.pincode-input-container .pincode-input-text {
  padding: 0 !important;
  margin: 0 2px;
  text-align: center;
  border: 1px solid;
  background: transparent;
  width: 50px;
  height: 50px;
  font-size: 20px;
  color: white;
  border-radius: 10px;
  padding-bottom: 9px !important;
}

.pincode-input-container .pincode-input-text:focus {
  outline: none;
  box-shadow: none;
  border-color: black !important;
}

/* Class for dropdown multiselect */
.go3425554998 {
  font-size: 12px;
  color: black;
}

.go2642161244 {
  padding: 4px !important;
}

.go2139093995 {
  width: 100%;
}

/*For 7 Columns for calendar*/

.seven-cols {
  grid-template-columns: repeat(7, minmax(0, 1fr));
  display: grid;
}


@media (max-width: 768px) {
  .success-screen a.btn-primary {
    margin-bottom: 1rem;
    margin-right: unset !important;
  }
}

@media (min-width: 768px) {

  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 100%;
    margin: 10px;
  }
}

@media (min-width: 992px) {

  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    margin: 10px;
  }
}

@media (min-width: 1200px) {

  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    margin: 10px;
  }
}

/*End Calendar*/

/*Put default normal screen values in here*/
@media screen and (min-width: 768px) {
  .table-lb {
    font-size: 1rem;
  }

  .link-img-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link-img-nav img {
    min-width: 300px;
    position: unset;
    top: unset;
  }

  .page-header-lb {
    font-size: 2rem;
    font-weight: 300;
  }

  .rank-image-lb {
    max-width: 100%;
    display: unset;
  }

  .firstPlace {
    font-family: sans-serif;
    color: gold;
    margin-bottom: 0;
    font-size: x-large;
  }

  .secondPlace {
    font-family: sans-serif;
    color: silver;
    margin-bottom: 0;
    font-size: larger;
  }

  .thirdPlace {
    font-family: sans-serif;
    color: #CD7F32;
    margin-bottom: 0;
    font-size: large;
  }

  .background-static-lb {
    background: url("assets/background-lb.jpeg") center no-repeat;
    background-size: 100% auto;
    background-position: top center;
    color: white;
    min-height: 100vh;
    overflow-x: unset;
  }

  .background-static-all {
    /* background: url("assets/background-all.jpeg") center no-repeat; */
    background-size: 100% auto;
    background-position: top center;
    min-height: 100vh;
    background-color: rgb(0, 0, 0);
  }

  .background-static-rp {
    background: url("assets/background-rp.jpeg") center no-repeat;
    background-size: 100% auto;
    background-position: top center;
    min-height: 100vh;
    background-color: rgb(0, 0, 0);
  }

  .col-link-footer {
    border-right: 1px solid white;
    display: flex;
    justify-content: center;
  }

  .row-title-rp {
    justify-content: flex-start;
  }

  .topdiv-footer {
    /* background-color: rgba(22,22,22,0.9);
    border-top: 1px solid darkgray; */
    padding: 20px;
  }

  .input-group-footer {
    margin-top: .5rem;
    width: 40% !important;
  }

  .divider-footer {
    justify-content: center;
    content: '';
    display: block;
    width: 75%;
    height: 1px;
    border-radius: 5px;
    background-color: darkgrey;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 14px;
  }

  .about-col-left-footer {
    color: white;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .about-col-right-footer {
    color: white;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .header-footer {
    color: white;
    margin-bottom: 0 !important;
    font-size: 1.2rem;
  }

  .row .small-logo-home {
    height: auto;
    width: 10%;
    margin: 0;
  }

  .row .small-logo-home2 {
    height: auto;
    width: 10%;
    margin: 0;
  }

  .set-width-100 {
    min-width: 100%;
    margin-bottom: 30px;
  }

  .description-teamspage {
    text-align: center;
    width: 100%;
    max-width: 654px;
    margin-left: 15px;
    margin-right: 15px;
  }

  div .members-textbox-teamspage {
    text-align: center;
    color: black;
    width: 100%;
  }

  .team-member-list {
    width: 100%;
    text-align: center;
  }

  .p-description-about {
    color: white;
    font-weight: 300;
    font-size: 22px;
    text-align: unset;
  }

  .row-description-about {
    justify-content: unset;
  }

  .p-groupname-rp {
    margin: 0;
    color: white;
    margin-bottom: .5rem;
  }

  .row-typeahead-rp {
    margin-bottom: 1rem;
  }

  .row-typeahead-rp .dropdown-item:focus,
  .dropdown-item:hover .rbt-highlight-text {
    background-color: transparent;
  }

  .row-typeahead-rp .dropdown-item:focus,
  .dropdown-item .rbt-highlight-text {
    background-color: transparent;
  }

  .row-success-rp {
    width: 100%;
  }

  .about-us {
    font-size: 18px;
  }

  .header-about-us {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .h5-hours-subheader {
    font-size: 1.25rem;
    color: white;
  }

  .dt-hours-info {
    font-size: 16px;
  }

  .p-hours-season-month {
    font-size: 16px;
  }

  .row-margin15-top {
    margin-top: 15px;
  }

  .team-rows {
    margin-right: 5px;
  }

  .p-hours-season {
    font-size: 16px;
  }

  .left-row-hours {
    display: flex !important;
    padding-left: 0px;
  }

  .right-row-hours {
    display: flex !important;
  }

  .last-row-hours {
    display: flex !important;
  }

  .col-hours-header {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .col-hours {
    text-align: left;
    display: flex;
    justify-content: flex-start;
  }

  .footer-text {
    font-size: 16px !important;
  }

  .td-lb {
    vertical-align: middle !important;
    padding: .75rem !important;
    text-align: center !important;
  }

  .td-name-lb {
    vertical-align: middle !important;
    padding: .75rem !important;
  }

  .td-tv-lb {
    vertical-align: middle !important;
    padding: 0 .75rem !important;
    text-align: left;
  }

  .header-lb {
    text-align: left;
  }

  .dl-hours-info {
    text-align: center;
  }

  .p-notice-text-home {
    color: rgba(148, 148, 148, 0.9);
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 0 !important;
    padding-top: 20px;
    text-align: center;
  }

  .pincode-input-container {
    justify-content: center;
    display: flex;
  }

  .pincode-input-container .pincode-input-text {
    font-size: 50px;
  }

  .img-logo-dashboard {
    height: auto;
    width: 15% !important;
    margin: 0;
  }

  .text-block-waiver-rp {
    margin-left: 5px !important;
  }

  .admin-button-email1 {
    font-size: 1rem !important;
    margin-right: 5px;
  }

  .admin-button-email2 {
    font-size: 1rem !important;
    margin-left: 5px;
    margin-right: 5px;
  }

  .admin-button-email3 {
    font-size: 1rem !important;
    margin-left: 5px;
  }

  .status-card-body-main-admin {
    max-height: 50vh;
  }

  .team-name {
    font-size: 1.2em;
  }

  .col-navigation-left-rp {
    display: flex;
    justify-content: flex-end;
  }

  .col-navigation-right-rp {
    display: flex;
    justify-content: flex-start;
  }

  .row-pagination-lb .pagination-col-lb {
    justify-content: flex-end;
  }

  .pagination-col-lb .MuiPagination-root {
    right: unset;
  }

  .row-header-lb {
    padding-top: 20px;
    margin-bottom: -10px;
    justify-content: unset;
  }

  .row-dropdown-months-lb {
    padding-bottom: 15px;
    justify-content: flex-start;
  }

  .col-dropdown-months-lb {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .team-pictures {
    max-width: 250px;
  }

  .td-team-imgname-teams a {
    color: white;
    font-size: 14px;
  }

  .col-team-picture-teams {
    display: flex;
    justify-content: flex-start;
  }

  .col-team-name-teams {
    margin-top: 0;
  }

  .row-title-about {
    justify-content: flex-start;
  }
}

/* Newest ipad formatting 
*/
@media only screen and (min-width: 810px) and (max-width: 1080px) {
  .text-block-waiver-rp {
    margin-left: 45px !important;
  }

  .col-hours-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col-hours {
    text-align: left;
    display: flex;
    justify-content: center;
  }

  .p-caption-instagram {
    font-size: .5rem;
  }

  .admin-button-email1 {
    font-size: .85rem !important;
    margin-right: 5px;
  }

  .admin-button-email2 {
    font-size: .85rem !important;
    margin-left: 5px;
    margin-right: 5px;
  }

  .admin-button-email3 {
    font-size: .85rem !important;
    margin-left: 5px;
  }

  .status-card-body-main-admin {
    max-height: 40vh;
  }
}

.add-participant-modal p.MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
  color: black;
}