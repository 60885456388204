.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.map {
  width: 100%;
  height: 100%;
  position: relative;
}

.google-map {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pin {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: #1a1a1a;
  border: 2px solid #3498db;
  border-radius: 25px;
  box-shadow: 0 2px 8px #00000026;
  color: #dadada;
  display: flex;
  padding: .75rem 1.25rem;
  position: relative;
  transform: translate(-6%, -6%);
  transition: all .3s ease;
  min-width: 250px;
}

/* Small pin style when zoomed out */
.pin-small {
  min-width: unset;
  width: 40px;
  height: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  justify-content: center;
  transform: translate(-6%, -6%);
  display: flex;
  align-items: center;
}

.pin-small .pin-icon {
  margin-right: 0;
  font-size: 1.5rem;
}

.pin-small .pin-text {
  display: none;
}

.pin:hover {
  transform: translate(-6%, -11%);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.pin-small:hover {
  transform: translate(-6%, -11%);
}

.pin-icon {
  color: #3498db;
  font-size: 1.2rem;
  margin-right: .75rem;
  flex-shrink: 0;
}

.pin-text {
  margin: 0;
  font-size: 0.9rem;
  white-space: normal;
  min-width: 0;
  flex: 1;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.2;
}

/* Tablet Styles */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .google-map {
    height: 350px;
  }

  .pin {
    padding: 0.6rem 1rem;
  }

  .pin-icon {
    font-size: 1.1rem;
  }

  .pin-text {
    font-size: 0.85rem;
    max-width: 180px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 767px) {
  .google-map {
    height: 300px;
  }

  .pin {
    padding: 0.5rem 0.875rem;
  }

  .pin-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .pin-text {
    font-size: 0.8rem;
    max-width: 150px;
  }

  .pin-small {
    width: 32px;
    height: 32px;
  }

  .pin-small .pin-icon {
    font-size: 1.2rem;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
  .google-map {
    height: 250px;
  }

  .pin {
    padding: 0.4rem 0.75rem;
  }

  .pin-text {
    max-width: 120px;
  }
}

/* Landscape Mode */
@media screen and (max-height: 480px) and (orientation: landscape) {
  .google-map {
    height: 200px;
  }
}

/* Animation for pin size transition */
.pin {
  transition: all 0.3s ease-in-out;
}